import React from "react";
import {Carousel} from "react-bootstrap";
import logo from "./../../image/logo-slider.png";
import huawei from "./../../image/link/huawei.png";
import iphone from "./../../image/link/appstore.png";
import android from "./../../image/link/googleplay.png";


const Slider = props =>{
    return  (
        <div className={'position-relative'} style={{overflow:"hidden"}}>
            <Carousel variant="dark">
                {
                    props.banner ?
                        props.banner.map(function (i,index) {
                            return (
                                <Carousel.Item key={index}>
                                    <div className={'img-slider'}>
                                        {
                                            props.click ?
                                                <a href={'/tai-app-nhan-qua'} target={'_blank'}>
                                                    <img
                                                        className="d-block w-100"
                                                        src={i}
                                                        alt="First slide"
                                                    />
                                                </a>
                                                :
                                                <img
                                                    className="d-block w-100"
                                                    src={i}
                                                    alt="First slide"
                                                />
                                        }

                                    </div>
                                </Carousel.Item>
                            );
                        })
                    :
                        <Carousel.Item>
                            <div className={'img-slider'}>
                                <img
                                    className="d-block w-100"
                                    src={'image/slider/slider45.png'}
                                    alt="First slide"
                                />
                            </div>
                        </Carousel.Item>
                }

            </Carousel>
            <Carousel.Caption>
                <img src={logo} alt="" width={150} className={'mb-md-4 hidden-xs'}/>
                <h2>{props.title}</h2>
                <p className={'m-0'}>{props.description}</p>
                {
                    props.link ?
                        <div className={'link-product-silder'}>
                            <a href="https://itunes.apple.com/app/id585253443?mt=8" target="_blank">
                                <img src={iphone} alt=""/>
                            </a>
                            <a  href="https://play.google.com/store/apps/details?id=com.somestudio.lichvietnam" target="_blank">
                                <img src={android} alt=""/>
                            </a>
                            <a  href="http://cdc.hispace.hicloud.com/000000hM" target="_blank">
                                <img src={huawei} alt=""/>
                            </a>
                        </div>
                    : ""
                }

            </Carousel.Caption>
        </div>

    );
}

export default Slider;