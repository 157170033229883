import React from "react";
import {Container} from "react-bootstrap";
import unlock_code from './../../image/taiappnhanqua/unlock_code.png';
import web from "./../../image/taiappnhanqua/image_web.png";
import mobile from "./../../image/taiappnhanqua/image_mobile.png";
// import image from "./../../image/taiappnhanqua/minh_hoa.png";
import KBank from "./../../image/taiappnhanqua/i_KBank.png";
import download from "./../../image/taiappnhanqua/download.png";
import register from "./../../image/taiappnhanqua/register.png";
import gift from "./../../image/taiappnhanqua/gift.png";
import {Helmet} from "react-helmet";

const TaiAppNhanQua = () => {
    return (
        <>
            <Helmet>
                <title>Lịch Việt | Tải app nhận quà</title>
            </Helmet>
            <Container className={'mt-4'}>
                <h5 className={'tb title-page'}>3 bước đơn giản để nhận thưởng từ <br/> KBank & Lịch Việt</h5>
                <div className={'steps mt-4'}>
                    <div className={'step'}>
                        <div className={'group_dotter'}>
                            <p className={'_icon'}>
                                <img src={download} width={23} alt=""/>
                            </p>
                            <span/>
                            <span/>
                            <span/>
                            <span/>
                            <span/>
                            <span/>
                            <span/>
                            <span/>
                            <span/>
                        </div>
                        <div className={'_text'}>
                            <p className={'step_title'}>Tải ứng dụng <b>K PLUS Vietnam</b> và nhập mã giới
                                thiệu <b>LICHVIET8</b></p>
                            <div className={'icon-kbank'}>
                                <a href="http://onelink.to/trram9">
                                    <img width={72} src={KBank} alt=""/>
                                </a>
                                <i className="fas fa-arrow-right"/>
                                <img className={'unlock_code'} src={unlock_code} alt="" width={25}/>
                                <div className={'ma_gioi_thieu'}>
                                    <p className={'text_mgt'}>Mã giới thiệu</p>
                                    <p className={'code_mgt'}>LICHVIET8</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'step'}>
                        <div className={'group_dotter'}>
                            <p className={'_icon'}>
                                <img style={{marginRight:"-5px"}} src={register} width={23} alt=""/>
                            </p>
                            <span/>
                            <span/>
                            <span/>
                        </div>

                        <div className={'_text'}>
                            <p className={'step_title'}>Đăng ký tài khoản <b>K PLUS Vietnam</b></p>
                        </div>
                    </div>
                    <div className={'step'}>
                        <div className={'group_dotter'}>
                            <p className={'_icon'}>
                                <img src={gift} width={21} alt=""/>
                            </p>
                        </div>
                        <div className={'_text'} style={{marginTop:"-15px"}}>
                            <p>
                                Nhận đến <span className={'style_price'}>100.000đ</span> vào tài khoản <b>KBank</b>
                            </p>
                            <p>& Mã Code <span className={'color_red tb'}>6 tháng Lịch Việt Pro</span></p>
                            <p className="step_title">Kích hoạt mã code tại đây: &nbsp;
                                <a href="https://lichviet.app/kich-hoat-pro" className="text-blue">
                                    <u>https://lichviet.app/kich-hoat-pro</u>
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className={'mt-2 dieu-khoan'}>
                        <i>
                            <a href="https://www.kasikornbank.com.vn/VN/Campaign/Pages/VN_KPLUS_LichViet_Promotion.aspx"
                               className={'underlined tb text-black'} target={'_blank'}>Điều khoản sử
                                dụng</a>
                        </i>
                    </div>
                    <div className={'pb-2'}>
                        <a href="http://onelink.to/trram9" className={'button__download no-hover'}>
                            tải ngay
                        </a>
                    </div>
                </div>

                <div className=" mx-auto steps_img-active hidden-xs col-md-9">
                    <img src={web} alt=""
                         width="250" className="img-scale-down"/>
                </div>
                <div className=" mx-auto hidden-md steps_img-active col-md-9">
                    <img src={mobile} alt=""
                         width="250" className="w-100 img-scale-down"/>
                </div>

            </Container>
        </>
    );
}

export default TaiAppNhanQua;