import React from "react";
import axios from "axios";

const SendUrl = (data, url, method, type = 1) => {
    var headers = null;
    const BASE_URL= process.env.REACT_APP_URL_API;
    var checkUser = localStorage.getItem('user');
    if (checkUser) {
        checkUser = JSON.parse(checkUser)
        headers = {
            secretKey: checkUser['secretKey'],
            apikey:"TCwrU5V2DBQtfa8pgNkTUgN6FGNsAkQA8181Suf2uNU1A3OeQa",
            accept: 'application/json'
        }
    }
    else {
        headers = {
            apikey:"TCwrU5V2DBQtfa8pgNkTUgN6FGNsAkQA8181Suf2uNU1A3OeQa",
            accept: 'application/json'
        }
    }
    var formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
        formData.append(key, value);
    }
    const out = axios({
        method: method,
        headers: headers,
        url: BASE_URL + url,
        data: formData,
    });
    out.catch(function (error) {
        if (error.response.status == 401) {
            localStorage.removeItem('user');
            var mess = "";
            if (checkUser) {
                mess = "Vui lòng đăng nhập để sử dụng chức năng này!";
            } else {
                mess = "Phiên đăng nhập hết hạn, đăng nhập lại để sử dụng chức năng này!";
            }
            if (window.confirm(mess)) {
                window.location.href = '/login';
            } else {
                window.location.reload();
            }
        }
    })
    return out;
}

export default SendUrl;