import React from "react";
import popup from "./../../image/popup.png";
import popupSuccess from "./../../image/popup_success.png";
import close from "./../../image/i_close.png";
import {Button} from "react-bootstrap";

const Popup = props => {
    return (
        <div className="popup">
            <div className="backgroup-popup" onClick={props.handleClose}>
            </div>
            <div className="popup-content">
                {
                    !props.img ?
                        <div className="close-popup" onClick={props.handleClose}>
                            <img src={close} alt="" width={20} height={20}/>
                        </div>
                        :""
                }
                <div className="img-popup">
                    {
                        props.img ?
                            <img src={popupSuccess} alt=""/>
                        :
                            <img src={popup} alt=""/>
                    }
                </div>
                <div className="text-popup">
                    <p className="tn">{props.text}</p>
                    <p className="">{props.text1}</p>
                </div>
                <div>
                    {props.button ?
                        <Button className="mt-2" onClick={props.handleClose}>Đóng</Button>
                        :
                        ""
                    }
                </div>
            </div>

        </div>
    );
}

export default Popup;