import React from "react";
import {Row, Col, Container, div } from "react-bootstrap";
import Slider from "../Ui/Slider";
import { NavLink } from "react-router-dom";
import user from "../../image/image_user.png";
import tuvi1 from "../../image/tuvi1.png";
import tuvi2 from "../../image/tuvi2.png";
import icon1 from "../../image/icon_kp1.png";
import {Helmet} from "react-helmet";


const TuVi = () => {
    const arr = [
        {name:"Xem tử vi",link:'https://deeplink.lichviet.app/lwInxnvwPmb'},
        {name:"Xông đất",link:'https://deeplink.lichviet.app/BsnE1qzwPmb'},
        {name:"Tử vi hàng ngày",link:'https://deeplink.lichviet.app/BoJZejnwPmb'},
        {name:"Tử vi trọn đời",link:'https://deeplink.lichviet.app/BoJZejnwPmb'},
        {name:"Đổi ngày",link:'https://deeplink.lichviet.app/Zdrrvg2wPmb'},
        {name:"12 cung hoàng đạo",link:'https://deeplink.lichviet.app/BoJZejnwPmb'},
        {name:"Xem sao",link:'https://deeplink.lichviet.app/BoJZejnwPmb'},
        {name:"La bàn",link:'https://deeplink.lichviet.app/BoJZejnwPmb'},
        {name:"Thước lỗ ban",link:'https://deeplink.lichviet.app/BoJZejnwPmb'},
        {name:"Văn khấn",link:'https://deeplink.lichviet.app/R3qwR1MwPmb'},
        {name:"Nhịp sinh học",link:'https://deeplink.lichviet.app/BoJZejnwPmb'},
        {name:"Giải mộng",link:'https://deeplink.lichviet.app/BoJZejnwPmb'},
        {name:"Bói tình duyên",link:'https://deeplink.lichviet.app/BoJZejnwPmb'},
    ];


    return (
        <>
            <Helmet>
                <title>Lịch Việt | Tử vi</title>
            </Helmet>
            <Slider
                banner={["image/slider/slider4.png"]}
                title={"Tử vi bói toán"}
                description={"Mở khóa không giới hạn những tính năng độc đáo, giúp đem lại trải nghiệm tuyệt vời nhất."}
                link={false}
            />
            <Container>
                <div className={"title-1 pt-md-4 pb-4"}>Tử vi bói toán</div>
                <div className={'d-gird-2__1'}>
                    <div className={'mb-2 gird'}>
                        <a href={'https://deeplink.lichviet.app/BoJZejnwPmb'} target="_blank">
                            <Row className={'box-user tuvi'}>
                                <Col md={3} className={'d-flex justify-content-center align-items-center'}>
                                    <img src={tuvi1} alt="" width={80} className={'img__mb-70'}/>
                                </Col>
                                <Col md={9} className={'p-0'}>
                                    <p className={'title2'}>Xem ngày tốt</p>
                                    <p>Giúp bạn tra cứu ngày cát tường để làm các việc đại sự</p>
                                </Col>
                            </Row>
                        </a>
                    </div>
                    <div className={'mb-2 gird'}>
                        <a href={'https://deeplink.lichviet.app/BoJZejnwPmb'} target="_blank">
                            <Row className={'box-user tuvi'}>
                                <Col md={3} className={'d-flex justify-content-center align-items-center'}>
                                    <img src={tuvi2} alt="" width={80} className={'img__mb-70'}/>
                                </Col>
                                <Col md={9} className={'p-0'}>
                                    <p className={'title2'}>Xem tử vi</p>
                                    <p>Lập lá số tử vi & xem vận mệnh</p>
                                </Col>
                            </Row>
                        </a>
                    </div>
                </div>
                <Row className={'list-icon-five my-4'}>
                    {
                        arr.map(function(i,index){
                            return (
                                <div key={index}>
                                    <a href={i.link} target="_blank">
                                        <img src={"/image/tuvi/item"+ parseInt(index + 1) +".png"} alt=""/>
                                        <p className={'title-2'}>{i.name}</p>
                                    </a>
                                </div>
                            );
                        })
                    }
                </Row>
            </Container>
        </>
    );
};

export default TuVi;