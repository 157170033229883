import React from "react";
import {Container} from "react-bootstrap";
import {Helmet} from "react-helmet";

const DieuKhoanSuDung = () => {

    return (<Container className={'py-4 '}>
        <Helmet>
            <title>Lịch Việt</title>
        </Helmet>
        <div className={'book'}>
            <h3 className={'tb'}>Điều khoản sử dụng</h3>

            <div>
                <span>Vui lòng đọc kỹ Thỏa Thuận Sử Dụng (“Thỏa Thuận”) trước khi bạn tiến hành tải, cài đặt, sử dụng tất cả hoặc bất kỳ phần nào của ứng dụng</span>
                <span>“Lịch Việt" (“Ứng Dụng”) (bao gồm nhưng không giới hạn phần mềm, các file và các tài liệu liên quan) hoặc sử dụng các dịch vụ do Công Ty Cổ Phần Truyền thông và Phát triển Phần mềm Cát Việt</span>
                (“PPCLINK”)
                <span> cung cấp để kết nối đến Ứng Dụng. Bạn chấp thuận và đồng ý bị ràng buộc bởi các quy định và điều kiện trong Thỏa Thuận này khi thực hiện các thao tác trên đây. Trường hợp bạn không đồng ý với bất kỳ điều khoản sử dụng nào của chúng tôi</span>
                <span>(phiên bản này và các phiên bản cập nhật), bạn vui lòng không tải, cài đặt, sử dụng Ứng dụng hoặc tháo gỡ Ứng Dụng ra khỏi thiết bị di động của bạn.</span>
            </div>
            <h4 className="mt-3"><span><b>1. Cập nhật</b></span>
            </h4>
            <div>
                <span>Thỏa Thuận này có thể được cập nhật thường xuyên bởi PPCLINK, phiên bản cập nhật sẽ được chúng tôi công bố tại website </span>
                <a href="https://lichviet.app/dieu-khoan-su-dung" target="_blank">https://lichviet.app/dieu-khoan-su-dung</a></div>
            <div><span> Phiên bản cập nhật sẽ thay thế cho các quy định và điều kiện trong thỏa thuận ban đầu. Bạn có thể truy cập vào Ứng Dụng hoặc vào website trên đây để xem nội dung chi tiết của phiên bản cập nhật.</span>
            </div>
            <h4 className="mt-3"><span><b>2. Giới Thiệu Về Ứng Dụng</b></span>
            </h4>
            <div><span>Lịch Việt là ứng dụng cẩm nang văn hoá giái trí, giúp bạn tra cứu nhanh ngày Âm - Dương, tạo, đồng bộ và tìm kiếm sự kiện trên tất cả các thiết bị bạn đăng nhập, cũng như tìm hiểu nhiều thông tin, kiến thức hữu ích về Văn hoá, lịch sử, sự kiện hay theo chủ đề,...</span>
            </div>
            <h4 className="mt-3"><span><b>3. Quyền Sở Hữu Ứng Dụng</b></span>
            </h4>
            <div><span>Ứng Dụng này được phát triển và sở hữu bởi PPCLINK, tất cả các quyền sở hữu trí tuệ liên quan đến Ứng Dụng</span>
                <span>(bao gồm nhưng không giới hạn mã nguồn, hình ảnh, dữ liệu, thông tin, nội dung chứa đựng trong Ứng Dụng; các sửa đổi, bổ sung, cập nhật của Ứng Dụng) và các tài liệu hướng dẫn liên quan</span>
                <span>(nếu có) sẽ thuộc quyền sở hữu duy nhất bởi PPCLINK và không cá nhân, tổ chức nào được phép sao chép, tái tạo, phân phối, hoặc hình thức khác xâm phạm tới quyền của chủ sở hữu nếu không có sự đồng ý và cho phép bằng văn bản của PPCLINK.</span>
            </div>
            <h4 className="mt-3"><span><b>4. Tài Khoản</b></span>
            </h4>
            <div><span>Để sử dụng Ứng Dụng bạn không cần phải lập tài khoản trực tuyến. Tuy nhiên để truy cập vào các tính năng như đồng bộ sự kiện, nhận thưởng và các tính năng khác cần định danh, bạn phải tạo một tài khoản theo yêu cầu của chúng tôi. Bạn cam kết rằng việc sử dụng tài khoản phải tuân thủ các quy định của PPCLINK, đồng thời tất cả các thông tin bạn cung cấp cho chúng tôi là đúng, chính xác, đầy đủ với tại thời điểm được yêu cầu. Mọi quyền lợi và nghĩa vụ của bạn sẽ căn cứ trên thông tin tài khoản bạn đã đăng ký, do đó nếu có bất kỳ thông tin sai lệch nào chúng tôi sẽ không chịu trách nhiệm trong trường hợp thông tin đó làm ảnh hưởng hoặc hạn chế quyền lợi của bạn.</span>
            </div>
            <h4 className="mt-3"><span><b>5. Quyển sử dụng tài nguyên ứng dụng</b></span>
            </h4>
            <div><span>Bạn có quyền sử dụng Ứng Dụng và các dịch vụ khác mà chúng tôi cung cấp, tuy nhiên việc sử dụng đó sẽ không bao gồm các hành vi sau đây nếu không có sự đồng ý bằng văn bản của PPCLINK.</span>
            </div>
            <ul className="listtype-bullet listindent1 list-bullet1">
                <li><span>Sao chép, chỉnh sửa, tái tạo, tạo ra sản phẩm mới hoặc phiên bản phái sinh trên cơ sở Ứng Dụng này;</span>
                </li>
                <li><span>Bán, chuyển giao, cấp quyền lại, tiết lộ hoặc hình thức chuyển giao khác hoặc đưa một phần hoặc toàn bộ Ứng Dụng cho bất kỳ bên thứ ba;</span>
                </li>
                <li><span>Sử dụng Ứng Dụng để cung cấp dịch vụ cho bất kỳ bên thứ ba</span>
                    <span>(tổ</span><span> chức, cá nhân);</span>
                </li>
                <li><span>Di chuyển, xóa bỏ, thay đổi bất kỳ thông báo chính đáng hoặc dấu hiệu nào của Ứng Dụng</span>
                    <span>(bao gồm nhưng không giới hạn các tuyên bố về bản quyền);</span>
                </li>
                <li><span>Thiết kế lại, biên dịch, tháo gỡ, chỉnh sửa, đảo lộn thiết kế của Ứng Dụng hoặc nội dung Ứng Dụng;</span>
                </li>
                <li><span>Thay đổi hoặc hủy bỏ trạng thái ban đầu của Ứng Dụng;</span>
                </li>
                <li><span>Sử dụng Ứng Dụng để thực hiện bất kỳ hành động gây hại cho hệ thống an ninh mạng của PPCLINK, bao gồm nhưng không giới hạn sử dụng dữ liệu hoặc truy cập vào máy chủ hệ thống hoặc tài khoản không được phép; truy cập vào hệ thống mạng để xóa bỏ, chỉnh sửa và thêm các thông tin; phát tán các chương trình độc hại, virus hoặc thực hiện bất kỳ hành động nào khác nhằm gây hại hoặc phá hủy hệ thống mạng;</span>
                </li>
                <li><span>Đăng nhập và sử dụng Ứng Dụng bằng một phần mềm tương thích của bên thứ ba hoặc hệ thống không được phát triển, cấp quyền hoặc chấp thuận bởi PPCLINK;</span>
                </li>
                <li><span>Sử dụng, bán, cho mượn, sao chép, chỉnh sửa, kết nối tới, phiên dịch, phát hành, công bố các thông tin liên quan đến Ứng Dụng, xây dựng mirror website để công bố các thông tin này hoặc để phát triển các sản phẩm phái sinh, công việc hoặc dịch vụ;</span>
                </li>
                <li><span>Sử dụng Ứng Dụng để đăng tải, chuyển, truyền hoặc lưu trữ các thông tin vi phạm pháp luật, vi phạm thuần phong mỹ tục của dân tộc;</span>
                </li>
                <li><span>Sử dụng Ứng Dụng để sử dụng, đăng tải, chuyển, truyền hoặc lưu trữ bất kỳ nội dung vi phạm quyền sở hữu trí tuệ, bí mật kinh doanh hoặc quyền pháp lý của bên thứ ba;</span>
                </li>
                <li><span>Sử dụng Ứng Dụng hoặc các dịch vụ khác được cung cấp bởi PPCLINK trong bất kỳ hình thức vi phạm pháp luật nào, cho bất kỳ mục đích bất hợp pháp nào;</span>
                </li>
                <li><span>Các hình thức vi phạm khác.</span>
                </li>
            </ul>
            <h4 className="mt-3"><span><b>6. Xử Lý Vi Phạm</b></span>
            </h4>
            <div><span>Trường hợp bạn vi phạm bất kỳ quy định nào trong Thỏa Thuận này, PPCLINK có quyền ngay lập tức khóa tài khoản của bạn và/hoặc xóa bỏ toàn bộ các thông tin, nội dung vi phạm, đồng thời tùy thuộc vào tính chất, mức độ vi phạm bạn sẽ phải chịu trách nhiệm trước cơ quan có thẩm quyền, PPCLINK và bên thứ ba về mọi thiệt hại gây ra bởi hoặc xuất phát từ hành vi vi phạm của bạn.</span>
            </div>
            <h4 className="mt-3"><span><b>7. Quyền Truy Cập và Thu Thập Thông Tin</b></span>
            </h4>
            <div><span>(a) Khi sử dụng Ứng Dụng, bạn thừa nhận rằng chúng tôi có quyền sử dụng những API hệ thống sau để truy cập vào dữ liệu trên điện thoại của bạn:</span>
                <span>(1)</span><span> Đọc và ghi vào danh bạ điện thoại,</span>
                <span>(2)</span><span> Lấy vị trí hiện tại của bạn khi được sự đồng ý,</span>
                <span>(3)</span><span> Ghi dữ liệu của Ứng Dụng lên thẻ nhớ,</span>
                <span>(4)</span>
                <span> Truy cập vào Internet từ thiết bị của bạn. Tất cả các truy cập này đều được chúng tôi thực hiện sau khi có sự đồng ý của bạn, vì vậy bạn cam kết và thừa nhận rằng, khi bạn đã cấp quyền cho chúng tôi, bạn sẽ không có bất kỳ khiếu nại nào đối với PPCLINK về việc truy cập này.</span>
            </div>
            <div><span>(b) Cùng với quyền truy cập, chúng tôi sẽ thu thập các thông tin sau của bạn</span>
            </div>
            <ul className="listtype-bullet listindent1 list-bullet1">
                <li><span>Thông tin cá nhân: bao gồm các thông tin bạn cung cấp cho chúng tôi để xác nhận tài khoản như tên, số điện thoại, số chứng minh nhân dân, địa chỉ email;</span>
                </li>
                <li><span>Thông tin chung: như các thông tin về cấu hình điện thoại của bạn, thông tin phiên bản PPCLINK mà bạn đang sử dụng cho điện thoại của mình;</span>
                </li>
                <li><span>Thông tin vị trí của bạn: dữ liệu về vị trí địa lý của bạn sẽ được lưu trữ trên máy chủ nhằm giúp bạn sử dụng chức năng tìm kiếm của Ứng Dụng;</span>
                </li>
                <li><span>Danh bạ điện thoại: chúng tôi sẽ lưu trữ danh bạ điện thoại của bạn trên máy chủ nhằm hỗ trợ tốt nhất cho bạn trong việc sử dụng Ứng Dụng và tránh trường hợp bạn bị mất dữ liệu. Chúng tôi cam kết sẽ tôn trọng và không sử dụng danh bạ điện thoại của bạn vì bất kỳ mục đích nào nếu không có sự đồng ý của bạn;</span>
                </li>
                <li><span>Chúng tôi không sử dụng bất kỳ biện pháp nào để theo dõi nội dung tin nhắn, trao đổi hoặc hình thức khác nhằm theo dõi người dùng khi sử dụng Ứng Dụng này.</span>
                </li>
            </ul>
            <div><span>(c) Nội dung của bạn bao gồm các thông tin liên lạc của bạn với người khác, bài viết hoặc phản hồi của bạn được gửi đến PPCLINK, tên người dùng của bạn, các hình ảnh, tài liệu, tệp âm thanh, sản phẩm kỹ thuật số, video và các tệp tin của bạn. Trong phạm vi cần thiết để cung cấp các phần mềm và dịch vụ cho bạn và những người khác, để bảo vệ bạn và các dịch vụ, cũng như để cải thiện sản phẩm và dịch vụ của PPCLINK, bạn cho phép PPCLNK để sử dụng nội dung của bạn hoàn toàn miễn phí trên các phần mềm và dịch vụ của PPCLINK trên phạm vi toàn thế giới; bao gồm quyền sao chép, lưu trữ, di chuyển, định dạng lại, hiển thị, chỉnh sửa, tạo ra tác phẩm phái sinh, và phân phối thông qua các công cụ truyền thông.</span>
            </div>
            <h4 className="mt-3"><span><b>8. Cam Kết Bảo Mật Thông Tin</b></span>
            </h4>
            <div><span>PPCLINK sử dụng các phương thức truyền tin an toàn https và mã hóa để truyền tải và lưu trữ các dữ liệu cá nhân và giao tiếp của bạn. Chúng tôi cam kết giữ bí mật tất cả thông tin mà bạn cung cấp cho PPCLINK hoặc chúng tôi thu thập từ bạn và không tiết lộ với bất kỳ bên thứ ba nào trừ khi có yêu cầu từ Cơ quan Nhà nước có thẩm quyền.</span>
            </div>
            <h4 className="mt-3"><span><b>9. Phí Và Các Khoản Thu</b></span>
            </h4>
            <div><span>PPCLINK cam kết không thu bất cứ khoản phí nào từ người dùng cho các dịch vụ cơ bản mà hiện tại chúng tôi đang cung cấp. Bạn có thể trả thêm phí để tham gia chương trình người dùng cao cấp của chúng tôi để được trải nghiệm ứng dụng không phải xem quảng cáo.</span>
            </div>
            <h4 className="mt-3"><span><b>10. Quảng cáo và các nội dung thương mại được phân phối bởi PPCLINK</b></span>
            </h4>
            <div><span>Khi sử dụng ứng dụng, bạn thừa nhận rằng chúng tôi có quyền sử dụng các thông tin không định danh của bạn nhằm cung cấp các nội dung quảng cáo đúng đối tượng.</span>
            </div>
            <h4 className="mt-3">
                <span><b>11. Liên Lạc Với Chúng Tôi</b></span>
            </h4>
            <div><span>- Địa chỉ email lichviet@ppclink.com</span>
            </div>
            <div><span>- Công Ty Cổ Phần Truyền thông và Phát triển Phần mềm Cát Việt: Số 5. Lô B, Tổ 34, Phường Dịch Vọng, Quận Cầu Giấy, Thành phố Hà Nội</span>
            </div>
            <div><span>Trân trọng cảm ơn bạn đã sử dụng sản phẩm và dịch vụ của chúng tôi.</span>
            </div>
        </div>
    </Container>);

}

export default DieuKhoanSuDung;