import React from "react";
import {Navbar, Nav, NavDropdown, Container, Row, div} from "react-bootstrap";
import Slider from "../Ui/Slider";
import lv from "../../image/lichviet.png"
import ImageProduct from "../Ui/ImageProduct";
import {Helmet} from "react-helmet";


const Product = () => {
    return (
        <>
            <Helmet>
                <title>Lịch Việt | Ứng dụng</title>
            </Helmet>
            <Slider
                banner={["image/slider/slider2.png"]}
                title={"Lịch Việt - Lịch Vạn Niên 2022"}
                description={"Ứng dụng Lịch Vạn Niên thân thiện và phổ biến nhất trên mobile. Hỗ trợ đồng bộ tài khoản Lịch Việt Online, xem ngày tốt xấu, quản lý sự kiện, đổi ngày âm dương, xem tử vi, 12 cung hoàng đạo... cùng nhiều nội dung truyền cảm hứng mỗi ngày!"}
                link={true}
            />
            <Container className={'mt-3'}>
                <div className={'d-flex justify-content-start align-items-start'}>
                    <img src={lv} alt="" width={150} className={'img__mb-70 '}/>
                    <div className={'ml-md-4 ml-3 my-auto'}>
                        <p className={'title-1 product'}>Lịch Việt - Lịch Vạn Niên 2022</p>
                        <p>Ứng dụng theo dõi và tra cứu Lịch Vạn Niên</p>
                    </div>
                </div>
                <div className={'mt-md-5'}>
                    <ImageProduct/>
                </div>
                <h1 className={'py-4'}>
                    Thông tin chi tiết
                </h1>
                <div className={'content-product'}>
                    <div className="content-description">
                        <div dir="ltr">Ứng dụng LỊCH VIỆT - LỊCH VẠN NIÊN 2022 phổ biến nhất trên mobile</div>
                        <div dir="ltr"></div>
                        <div dir="ltr">► Xem ngày âm năm Kỷ Hợi, tử vi, xông đất năm mới 2022</div>
                        <div dir="ltr">► Xem Tử Vi 2022, xông đất năm Tân Sửu</div>
                        <div dir="ltr">► Xem thời tiết của nhiều ngày liên tiếp và nhiều địa điểm khác nhau</div>
                        <div dir="ltr">► Xem ngày tốt: ngày cưới, động thổ, mua xe, xuất hành,...</div>
                        <div dir="ltr">► Lập lá số tử vi theo ngày giờ sinh, có hỗ trợ xem Luận giải chi tiết những diễn
                            biến xảy ra trong đời người, xem chi tiết lý tính từng sao, xem sao tam hợp, xung chiếu,
                            cung giáp,... Xem tử vi giúp bạn hiểu rõ được vận mệnh của chính mình và đưa ra lời khuyên
                            ứng xử phù hợp với cuộc đời
                        </div>
                        <div dir="ltr">► Gửi thiệp chúc Tết, Valentine, 8/3 và các dịp quan trọng trong năm</div>
                        <div dir="ltr">► Xem tử vi 12 cung hoàng đạo (Horoscope) hàng ngày</div>
                        <div dir="ltr">► Đếm xuôi - Đếm ngược các sự kiện quan trọng của bạn</div>
                        <div dir="ltr">► Bộ hình nền tuổi thơ dân gian đặc sắc</div>
                        <div dir="ltr">► Đăng kí và đồng bộ tài khoản Lịch Việt Online, kết nối tài khoản Lịch
                            Google/Facebook
                        </div>
                        <div dir="ltr">► Tính năng Trang chủ hoàn toàn mới giúp bạn tra cứu nhanh và liên kết tới toàn
                            bộ tính năng hữu ích
                        </div>
                        <div dir="ltr">► Chọn chủ đề danh ngôn và chia sẻ danh ngôn lên Facebook</div>
                        <div dir="ltr">► Thêm sự kiện, nhắc nhở theo lịch Âm: nhắc ngày giỗ, ngày sinh nhật, cưới
                            hỏi,...
                        </div>
                        <div dir="ltr">► Widget calendar: Tra cứu nhanh ngày âm dương trong tháng mà không phải mở ứng
                            dụng
                        </div>
                        <div dir="ltr"></div>
                        <div dir="ltr">► Những Tính Năng Nổi Bật:</div>
                        <div dir="ltr"></div>
                        <div dir="ltr">• Trang chủ + Lịch tháng:</div>
                        <div dir="ltr">- Tra cứu nhanh thông tin hàng ngày</div>
                        <div dir="ltr">- Truy cập nhanh tới các tính năng hữu ích</div>
                        <div dir="ltr">- Truyền cảm hứng hàng ngày với danh ngôn đặc sắc</div>
                        <div dir="ltr">- Xem nhanh ngày Âm, Dương theo tháng</div>
                        <div dir="ltr">- Xem danh sách sự kiện quan trọng</div>
                        <div dir="ltr">- Xem ngày hoàng đạo, hắc đạo trong tháng</div>
                        <div dir="ltr">- Xem video truyền cảm hứng hàng ngày</div>
                        <div dir="ltr">- Xem Lịch chiếu phim hàng ngày</div>
                        <div dir="ltr">- Xem danh sách sự kiện Đếm xuôi ngược yêu thích</div>
                        <div dir="ltr">- Xem sự kiện Ngày này năm xưa: Khám phá các sự kiện Quốc tế &amp; Việt Nam đã
                            xảy ra trong quá khứ
                        </div>
                        <div dir="ltr"></div>
                        <div dir="ltr">• Lịch Ngày</div>
                        <div dir="ltr">- Xem lịch Âm, lịch Dương</div>
                        <div dir="ltr">- Xem can, chi của ngày, tháng, năm</div>
                        <div dir="ltr">- Xem giờ Hoàng đạo</div>
                        <div dir="ltr">- Xem tuổi xung, tiết khí, hướng xuất hành</div>
                        <div dir="ltr">- Xem thông tin về ngày Tam nương, Nguyệt kỵ, Tứ tuyệt,...</div>
                        <div dir="ltr">- Hiển thị danh ngôn theo chủ đề và thay đổi theo ngày</div>
                        <div dir="ltr">- Xem thời tiết trong ngày và các ngày kế tiếp</div>
                        <div dir="ltr">- Giao diện đẹp với nhiều hình nền vui nhộn</div>
                        <div dir="ltr"></div>
                        <div dir="ltr">• Lịch Sự kiện</div>
                        <div dir="ltr">- Hiển thị danh sách sự kiện ngày lễ, ngày giỗ, hiếu hỷ</div>
                        <div dir="ltr">- Tạo sự kiện mới nhanh chóng với giao diện bắt mắt</div>
                        <div dir="ltr">- Tìm kiếm sự kiện theo chủ đề</div>
                        <div dir="ltr">- Liên kết sự kiện từ Google Calendar và Facebook Events</div>
                        <div dir="ltr">- Tìm kiếm và lưu thông tin lịch chiếu phim</div>
                        <div dir="ltr"></div>
                        <div dir="ltr">• Tính Ngày</div>
                        <div dir="ltr">- Đổi một ngày bất kỳ từ lịch Dương sang lịch Âm và ngược lại</div>
                        <div dir="ltr">- Tính khoảng cách giữa hai ngày</div>
                        <div dir="ltr">- Cộng, Trừ ngày Âm - Dương</div>
                        <div dir="ltr"></div>
                        <div dir="ltr">• Văn hóa Việt</div>
                        <div dir="ltr">- Phong tục truyền thống, lễ hội trong năm</div>
                        <div dir="ltr">- Bộ sưu tập các bài đồng dao, hát ru</div>
                        <div dir="ltr">- Trò chơi dân gian</div>
                        <div dir="ltr"></div>
                        <div dir="ltr">• Khám phá</div>
                        <div dir="ltr">- Xem Sao trong Năm</div>
                        <div dir="ltr">- Gửi thiệp chúc mừng các dịp quan trọng trong năm</div>
                        <div dir="ltr">- Giải mộng</div>
                        <div dir="ltr">- Tử vi hàng năm</div>
                        <div dir="ltr">- Bói tình duyên</div>
                        <div dir="ltr">- Đếm xuôi đếm ngược</div>
                        <div dir="ltr">- Xem cung hoàng đạo (Horoscope) hàng ngày</div>
                        <div dir="ltr">- La bàn phong thuỷ, thước Lỗ Ban</div>
                        <div dir="ltr">- Và rất nhiều tính năng khác đang được hoàn thiện</div>
                        <div dir="ltr"></div>
                        <div dir="ltr">► Về việc cấp quyền cho ứng dụng Lịch Việt</div>
                        <div dir="ltr">Khác với phiên bản trước, phiên bản mới của Lịch Việt có thêm tính năng tạo tài
                            khoản, đồng bộ dữ liệu online, kết nối với Facebook và Google Calendar. Vì vậy, phiên bản
                            này có yêu cầu xin thêm một số quyền để trải nghiệm ứng dụng được trơn tru. Chúng tôi đảm
                            bảo sử dụng các quyền có được đúng mục đích để bạn có thể an tâm khi sử dụng.
                        </div>
                        <div dir="ltr"></div>
                        <div dir="ltr">Chúng tôi sử dụng các quyền định vị và thông báo đẩy để cập nhật cho bạn nhưng
                            thông tin thời tiết, sự kiện quan trọng hàng ngày. Quyền Camera để chụp ảnh đại diện và một
                            số tính năng cá nhân hoá sắp tới của Lịch Việt.
                        </div>
                        <div dir="ltr"></div>
                        <div dir="ltr">PPCLINK tự hào là công ty đầu tiên phát triển ứng dụng lịch cho người Việt trên
                            mobile và chúng tôi vẫn tiếp tục nâng cấp để sản phẩm ngày càng hoàn thiện hơn. Nếu gặp vấn
                            đề khi sử dụng ứng dụng, xin vui lòng gửi phản hồi cho chúng tôi qua email
                            lichviet@ppclink.com hoặc tại trang Facebook "Lịch Việt - Lịch Vạn Niên". Xin cảm ơn tất cả
                            những đóng góp và phản hồi quý báu của các bạn!
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
}

export default Product;