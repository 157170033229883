import React, {useEffect, useState} from "react";
import {Container, Row, Col, Button, InputGroup, FormControl} from "react-bootstrap";
import iconPro from "./../../image/icon_pro.png";
import active1 from "./../../image/active1.png";
import active2 from "./../../image/active2.png";
import activemb from "./../../image/active-mb.png";
import SendUrl from "../Ui/SendDataUrl";
import LoaderData from "../Ui/loader";
import userdefault from "../../image/default-profile.png";
import Popup from "../Ui/Pogup";
import {Helmet} from "react-helmet";

function checkPr(user) {
    const detailPremium = user.premiums[0];
    const checkPremium = user.premium && user.premium == 1 ? true : false;
    return (
        <>
            <p>{checkPremium ? "Thành viên Pro từ " + detailPremium.start_time.split(" ")[0] : "Chưa phải thành viên Pro"}</p>
            <p>HSD: {checkPremium ? detailPremium.end_time.split(" ")[0] : ""}</p>
        </>
    );

}

const Active = () => {
        const getUserLocal = JSON.parse(localStorage.getItem('user'));
        if (!getUserLocal) {
            window.localStorage.setItem('link_redirect','/kich-hoat-pro')
            window.location.href = '/login'
        }
        const [user, setUser] = useState(getUserLocal);
        const [code, setCode] = useState("");
        const [load, setLoad] = useState(false);
        const [messagePopup, setMessagePopup] = useState("");
        const [isOpen, setIsOpen] = useState(false);

        const activeCode = e => {
            e.preventDefault(e);
            if (user && code) {
                setLoad(true);
                SendUrl({code: code}, '/api/user/active-pro', 'POST').then(
                    function (response) {
                        setLoad(false);
                        if (response.data.status == 0) {
                            alert('Mã kích hoạt không đúng hoặc đã được kích hoạt !');
                        } else {
                            var newUser = user;
                            var pr = response.data.data;
                            newUser.premiums[0] = pr;
                            newUser.premium = 1;
                            setUser(newUser);
                            localStorage.setItem('user', JSON.stringify(newUser));
                            setIsOpen(true);
                            setMessagePopup('Kích hoạt thành công. Hạn sử dụng Lịch Việt Pro: ' + pr.end_time.split(" ")[0] + '.')
                        }
                    },
                    function (error) {
                        setLoad(false)
                        console.log(error)
                    }
                );
            }
            ;
        };
        const updateCode = (e) => {
            var value = e.target.value;
            setCode(value.toUpperCase());
        };

        return (
            <>
                <Helmet>
                    <title>Lịch Việt | Kích hoạt Pro</title>
                </Helmet>
                <Container className={'pb-md-5'}>
                    {load ?
                        <div className={'fixed-loader'}>
                            {LoaderData('big')}
                        </div>
                        : ""
                    }
                    <div className={"text-center title-1 py-4 tl-mobile "}> Kích hoạt Lịch Việt Pro bằng mã</div>
                    <div className={'d-flex justify-content-center'}>
                        <Row className={'box-user'}>
                            <Col md={3} className={'d-flex justify-content-center align-items-center'}>
                                <div className="position-relative d-inline">
                                    {
                                        user.avatar == "" || !user.avatar ?
                                            <img src={userdefault} alt="Avatar" width={80} className={'avatar'}/>
                                            :
                                            <img src={user.avatar} alt="Avatar" width={80} className={'avatar'}/>
                                    }
                                    {
                                        user.premium == 1 ?
                                            <img src={iconPro} alt="" className={'icon-pro-user'} width={35}/>
                                            :
                                            ""
                                    }

                                </div>
                            </Col>
                            <Col md={9}>
                                <p className={'title2'}>{user.full_name}</p>
                                {checkPr(user)}
                                <p>ID: {user.id}</p>
                            </Col>
                        </Row>
                    </div>
                    <Row className={'justify-content-center mt-md-4 '}>
                        <Col md={4} className={'text-center zindex'}>
                            <form action="" onSubmit={activeCode}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text name={'code-ctive'}><i
                                        className="fas fa-unlock-alt color-icon"></i></InputGroup.Text>
                                    <FormControl placeholder="Nhập mã kích hoạt" value={code} onChange={updateCode}/>
                                </InputGroup>
                                <Button type="submit" className={"px-4 mt-md-4"}>Kích hoạt</Button>
                            </form>
                        </Col>
                        <Col md={9} className={' mx-auto d-flex justify-content-between img-active'}>
                            <img src={active1} alt="" width={250} className={"img-scale-down hidden-xs"}/>
                            <img src={active2} alt="" width={250} className={"img-scale-down hidden-xs"}/>
                            <img src={activemb} alt="" className={"img-scale-down hidden-md"}/>
                        </Col>
                    </Row>
                    {isOpen && <Popup
                        handleClose={() => {
                            window.location.assign("/");
                        }}
                        text={messagePopup}
                        text1=" Cảm ơn quý khách!"
                        button={true}
                        img={true}
                    />}
                </Container>
            </>
        );
    }
;


export default Active;