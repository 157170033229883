import React, {useState} from "react";
import {Button, Card, Col, Container, FormControl, InputGroup, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import Slider from "../Ui/Slider";
import img1 from "../../image/lv_pro1.png";
import img2 from "../../image/lv_pro2.png";
import img3 from "../../image/lv_pro3.png";
import img4 from "../../image/lv_pro4.png";
import km from "./../../image/promotion.png";
import why1 from "../../image/why1.png";
import why2 from "../../image/why2.png";
import why3 from "../../image/why3.png";
import why4 from "../../image/why4.png";
import why5 from "../../image/why5.png";
import why6 from "../../image/why6.png";
import active1 from "../../image/active1.png";
import active2 from "../../image/active2.png";
import activemb from "../../image/active-mb.png";
import SendUrl from "../Ui/SendDataUrl";
import LoaderData from "../Ui/loader";
import Popup from "../Ui/Pogup";
import {Helmet} from "react-helmet";

const ActivePro = () => {

    const [load, setLoad] = useState(false);
    const [messagePopup, setMessagePopup] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const OnSubmitSupport = e =>{
        e.preventDefault(e);
        const getUserLocal = JSON.parse(localStorage.getItem('user'));
        const data = {
            phone:e.target[0].value,
            email:e.target[1].value
        }
        if (getUserLocal) {
            if (checkPhone(data.phone)){
                setLoad(true);
                SendUrl(data, '/api/user/support', 'POST').then(
                    function (response) {
                        setLoad(false);
                        if (response.data.status == 0) {
                            alert(response.data.message);
                        } else {
                            setIsOpen(true);
                            setMessagePopup("Lịch Việt đã nhận được thông tin của quý khách & sẽ liên hệ lại trong thời gian sớm nhất.")
                        }
                    },
                    function (error) {
                        setLoad(false)
                        console.log(error)
                    }
                );
            }else {
                alert('Số điện thoại sai định dạng!');
            }
        }else {
            alert('Vui lòng đăng nhập để sử dụng chức năng này!');
        }
    }
    function checkPhone(number) {
        return /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/.test(number);
    }
    return (
        <>
            <Helmet>
                <title>Lịch Việt | Gói Lịch Việt</title>
            </Helmet>
            <Slider
                banner={["image/slider/slider3.png"]}
                title={"Nâng cấp Lịch Việt - Lịch Vạn Niên 2022 Pro"}
                description={"Mở khóa không giới hạn những tính năng độc đáo, giúp đem lại trải nghiệm tuyệt vời nhất."}
                link={false}
            />
            {isOpen && <Popup
                handleClose={() => { window.location.reload(); }}
                text={messagePopup}
                text1={'Trân trọng!'}
                button={true}
                img={true}
            />}
            {
                load ?
                <div className={'fixed-loader'}>
                    {LoaderData('big')}
                </div>
                : ""
            }
            <Container className={'pb-4'}>
                <div className={"title-1 py-4"}>Các gói Lịch Việt Pro</div>
                <div className={'gird-4__2 package-pro'}>
                    <div className={'gird'}>
                        <Card>
                            <a href={'https://deeplink.lichviet.app/GTo8m0ExPmb'} target="_blank">
                                <Card.Body>
                                    <Card.Title className={'month'}>6 Tháng</Card.Title>
                                    {/*<Card.Subtitle className="mb-2 km ">*/}
                                    {/*    <div className={'position-relative text-center'}>*/}
                                    {/*        <img src={km} alt="" width={45}/>*/}
                                    {/*        <p className={'number-promotion'}>-68</p>*/}
                                    {/*    </div>*/}
                                    {/*</Card.Subtitle>*/}
                                    <Card.Text className={'text-center py-md-4'}>
                                        <img src={img1} alt="" width={150} className={'img__mb-100'}/>
                                    </Card.Text>
                                    <Card.Text className={'text-center mb-1'}>
                                        {/*&nbsp;*/}
                                    </Card.Text>
                                    <Card.Text className={'text-center price-card'}>69.000 đ</Card.Text>
                                </Card.Body>
                            </a>
                        </Card>
                    </div>
                    <div className={'gird'}>
                        <Card>
                            <a href="https://deeplink.lichviet.app/GTo8m0ExPmb" target="_blank">
                                <Card.Body>
                                    <Card.Title className={'month'}>1 năm</Card.Title>
                                    <Card.Subtitle className="mb-2 km ">
                                        {/*<div className={'position-relative text-center'}>*/}
                                        {/*    <img src={km} alt="" width={45}/>*/}
                                        {/*    <p className={'number-promotion'}>-68</p>*/}
                                        {/*</div>*/}
                                    </Card.Subtitle>
                                    <Card.Text className={'text-center py-md-4'}>
                                        <img src={img2} alt="" width={150} className={'img__mb-100'}/>
                                    </Card.Text>
                                    <Card.Text className={'text-center mb-1'}>
                                        {/*<del className={'price-promotion'}>200.000 VNĐ</del>*/}
                                    </Card.Text>
                                    <Card.Text className={'text-center price-card'}>89.000 đ</Card.Text>
                                </Card.Body>
                            </a>
                        </Card>
                    </div>
                    <div className={'gird'}>
                        <Card>
                            <a href="https://deeplink.lichviet.app/GTo8m0ExPmb" target="_blank">
                                <Card.Body>
                                    <Card.Title className={'month'}>2 năm</Card.Title>
                                    <Card.Subtitle className="mb-2 km ">
                                        {/*<div className={'position-relative text-center'}>*/}
                                        {/*    <img src={km} alt="" width={45}/>*/}
                                        {/*    <p className={'number-promotion'}>-59</p>*/}
                                        {/*</div>*/}
                                    </Card.Subtitle>
                                    <Card.Text className={'text-center py-md-4'}>
                                        <img src={img3} alt="" width={150} className={'img__mb-100'}/>
                                    </Card.Text>
                                    <Card.Text className={'text-center mb-1'}>
                                        {/*<del className={'price-promotion'}>300.000 VNĐ</del>*/}
                                    </Card.Text>
                                    <Card.Text className={'text-center price-card'}>149.000 đ</Card.Text>
                                </Card.Body>
                            </a>
                        </Card>
                    </div>
                    {/*<div className={'gird'}>*/}
                    {/*    <Card>*/}
                    {/*        <a href="https://deeplink.lichviet.app/GTo8m0ExPmb" target="_blank">*/}
                    {/*            <Card.Body>*/}
                    {/*                <Card.Title className={'month'}>Trọn đời</Card.Title>*/}
                    {/*                <Card.Subtitle className="mb-2 km ">*/}
                    {/*                    /!*<div className={'position-relative text-center'}>*!/*/}
                    {/*                    /!*    <img src={km} alt="" width={45}/>*!/*/}
                    {/*                    /!*    <p className={'number-promotion'}>-61</p>*!/*/}
                    {/*                    /!*</div>*!/*/}
                    {/*                </Card.Subtitle>*/}
                    {/*                <Card.Text className={'text-center py-md-4'}>*/}
                    {/*                    <img src={img4} alt="" width={150} className={'img__mb-100'}/>*/}
                    {/*                </Card.Text>*/}
                    {/*                <Card.Text className={'text-center mb-1'}>*/}
                    {/*                    /!*<del className={'price-promotion'}>350.000 VNĐ</del>*!/*/}
                    {/*                </Card.Text>*/}
                    {/*                <Card.Text className={'text-center price-card'}>199.000 đ</Card.Text>*/}
                    {/*            </Card.Body>*/}
                    {/*        </a>*/}
                    {/*    </Card>*/}
                    {/*</div>*/}
                </div>
                <div className={"title-1 py-4 mt-3"}>Tại sao bạn nên nâng cấp Lịch Việt Pro</div>
                <Row className={'w-100 ml-0 box-style'}>
                    <Col md={6}>
                        <Row className={'box-user active-pro'}>
                            <Col md={3} className={'d-flex justify-content-center align-items-center'}>
                                <img src={why1} alt="" width={80} className={'img__mb-70'}/>
                            </Col>
                            <Col md={9} className={'my-auto'}>
                                <p className={'title2'}>Tắt quảng cáo</p>
                                <p>Các nội dung quảng cáo từ các mạng quảng cáo hiển thị hiện có sẽ được ẩn đi để mang
                                    lại trải nghiệm tốt nhất cho người dùng PRO.</p>
                            </Col>
                        </Row>
                        <Row className={'box-user active-pro'}>
                            <Col md={3} className={'d-flex justify-content-center align-items-center'}>
                                <img src={why2} alt="" width={80} className={'img__mb-70'}/>
                            </Col>
                            <Col md={9} className={'my-auto'}>
                                <p className={'title2'}>Mở Khóa Tính Năng Tử Vi Độc Quyền</p>
                                <p>Truy cập không giới hạn 2 tính năng Tử Vi độc quyền của Lịch Việt đó là Luận Giải lá
                                    số Tử Vi trọn đời theo Bát Tự và Xem Ngày Tốt Xấu.</p>
                            </Col>
                        </Row>
                        <Row className={'box-user active-pro'}>
                            <Col md={3} className={'d-flex justify-content-center align-items-center'}>
                                <img src={why3} alt="" width={80} className={'img__mb-70'}/>
                            </Col>
                            <Col md={9} className={'my-auto'}>
                                <p className={'title2'}>Cá Nhân Hóa Trang Chủ</p>
                                <p>Người dùng PRO được tự do thay đổi hiển thị của các tính năng trên Trang Chủ và có
                                    thể đặt thẻ Lịch Ngày làm Trang Chủ khi mở ứng dụng.</p>
                            </Col>
                        </Row>
                    </Col>
                    <hr className={'hidden-md'}/>
                    <Col md={6}>
                        {/*<Row className={'box-user active-pro'}>*/}
                        {/*    <Col md={3} className={'d-flex justify-content-center align-items-center'}>*/}
                        {/*        <img src={why4} alt="" width={80} className={'img__mb-70'}/>*/}
                        {/*    </Col>*/}
                        {/*    <Col md={9} className={'my-auto'}>*/}
                        {/*        <p className={'title2'}>Tạo Mới & Đồng Bộ Sự Kiện</p>*/}
                        {/*        <p>Tạo mới các sự kiện theo nhu cầu cá nhân ngay trên ứng dụng Lịch Việt và đồng bộ hóa*/}
                        {/*            các sự kiện đã tạo với các tài khoản được liên kết.</p>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        <Row className={'box-user active-pro'}>
                            <Col md={3} className={'d-flex justify-content-center align-items-center'}>
                                <img src={why5} alt="" width={80} className={'img__mb-70'}/>
                            </Col>
                            <Col md={9} className={'my-auto'}>
                                <p className={'title2'}>Trải Nghiệm Các Tính Năng Mới</p>
                                <p>Được trải nghiệm không giới hạn các tính năng mới được cập nhật trong tương lai chỉ
                                    dành riêng cho phiên bản PRO.</p>
                            </Col>
                        </Row>
                        <Row className={'box-user active-pro'}>
                            <Col md={3} className={'d-flex justify-content-center align-items-center'}>
                                <img src={why6} alt="" width={80} className={'img__mb-70'}/>
                            </Col>
                            <Col md={9} className={'my-auto'}>
                                <p className={'title2'}>Hỗ Trợ Giải Đáp Đa Kênh</p>
                                <p>Ưu tiên hỗ trợ giải đáp thắc mắc và khắc phục lỗi từ đội ngũ Chăm Sóc Khách Hàng trên
                                    các kênh liên lạc chính thức của Lịch Việt.</p>
                            </Col>
                        </Row>
                    </Col>
                    <hr className={'hidden-md'}/>
                </Row>

                {/*thanh toán*/}


                <div className={"title-1 py-4 mt-3"}>Quý khách cần hỗ trợ?</div>
                <Col md={8} className={'mx-auto text-center'}>
                    <div className={'box-style support p-md-4'}>
                        <div className={'text-center px-md-5 mx-md-5 tl-mobile'}>
                            Quý khách vui lòng nhập chính xác thông tin.
                            Chúng tôi sẽ liên hệ lại trong thời gian sớm nhất
                        </div>
                        <form action="#" onSubmit={OnSubmitSupport}>
                            <Row className={'justify-content-center mt-4 '}>

                                <Col md={6} className={'text-center zindex'}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text><i className="fas fa-phone-alt color-icon"></i></InputGroup.Text>
                                        <FormControl required placeholder="Số điện thoại"/>
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text><i className="fas fa-envelope color-icon"></i></InputGroup.Text>
                                        <FormControl required type={'email'} placeholder="Email"/>
                                    </InputGroup>
                                    <Button type={'submit'} className={"px-5 mt-md-4"}>Gửi</Button>

                                </Col>
                                <Col md={12} className={'d-flex justify-content-between img-active'}>
                                    <img src={active1} alt="" width={250} className={"img-scale-down hidden-xs"}/>
                                    <img src={active2} alt="" width={250} className={"img-scale-down hidden-xs"}/>
                                    <img src={activemb} alt="" className={"img-scale-down hidden-md"}/>
                                </Col>
                            </Row>
                        </form>
                    </div>
                </Col>
            </Container>
        </>
    );

};

export default ActivePro;