import {useEffect} from "react";

const FacebookMessagerChat = () => {

    useEffect(() => {
        const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
        var chatbox = document.getElementById('fb-customer-chat');
        chatbox.setAttribute("page_id", "178461482516191");
        chatbox.setAttribute("attribution", "biz_inbox");
        window.fbAsyncInit = function () {
            window.FB.init({
                appId      : FACEBOOK_APP_ID, // App ID
                channelURL : '', // Channel File, not required so leave empty
                status     : true, // check login status
                cookie     : true, // enable cookies to allow the server to access the session
                oauth      : true, // enable OAuth 2.0
                xfbml: true,
                version: 'v12.0'
            });
        };
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'))
    })
    return (
        <>
            <div id="fb-root"/>
            <div id="fb-customer-chat" className="fb-customerchat"/>
        </>
    )
}
export default FacebookMessagerChat;