import React, {useEffect, useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import Select from 'react-select';
import TinhAmLich from "./AmLich";
import troi from "../../image/troi.png";
import trang from "../../image/trang.png";
import NameCalendar from "./NameCalendar";
import GioHoangDao from "./GioHoangDao";
import weather from "../../image/weather.png";
import saonam from "./../../image/sao_nam.png"
import SendUrl from "./SendDataUrl";
import LoaderData from "./loader";
import SaoNamCP from "./SaoNamCP";
import hoa_trai from "./../../image/hoa_trai.png";
import hoa_phai from "./../../image/hoa_phai.png";
import TietKhi from "./TietKhi";
import TietKhiPopup from "./TietKhiPopup";

const kslh = [
    {id: 1, name: "Khai sơn lập hướng, tu phương cát"},
    {id: 2, name: "Tam nguyên Tử bạch"},
    {id: 3, name: "Cai sơn Hoàng Đạo"},
    {id: 4, name: "Thông thiên khiếu"},
    {id: 5, name: "Tẩu mã lục Nhâm"},
    {id: 6, name: "Tứ lợi tam nguyên"},
    {id: 7, name: "Khai sơn, lập phương, tu phương hung"},
    {id: 8, name: "Khai sơn hung"},
    {id: 9, name: "Lập hướng hung"},
    {id: 10, name: "Tu phương hung"}
];

function SearchSao(data, date) {
    var tam_nguyen = {
        0: 'Hạ nguyên',
        1: 'Thượng nguyên',
        2: 'Trung nguyên',
    }
    var out = [];
    const updateOut = (v, i) => {
        if (v.length > 0) {
            out[i] = v
        }
    }

    kslh.forEach((e, a) => {
        if (e.id != 4 && e.id != 2) {
            updateOut(data.filter((k) => {
                return k.id_kslh === e.id
            }), e.id)

        } else if (e.id == 4) {
            var data4 = data.filter((k) => {
                return k.id_kslh == e.id
            }).map(i => {
                return {ten_son: i.ten_son, ten_sao: i.sao}
            })
            var id = "";
            var index = -1;
            var obj = {};
            var arr = [];
            data4.map(function (e, v) {
                if (e.ten_sao != id) {
                    index += 1;
                    arr[index] = {};
                    id = e.ten_sao;
                    obj = {id_kslh: e.id_kslh, ten_sao: e.ten_sao, son: [e.ten_son]};
                    arr[index] = obj;
                } else {
                    arr[index].son.push(e.ten_son)
                    arr[index] = obj;
                }
            })
            updateOut(arr, e.id)
        } else if (e.id == 2) {
            var abs = Math.floor(Math.abs(date.y - 1984) / 57);
            var check = abs;
            if (abs > 2) check = abs % 3;
            updateOut(data.filter((k) => {
                return k.id_kslh === e.id && k.tam_nguyen == tam_nguyen[check]
            }), e.id)
        }

    });
    return out;

}

function XungKhac(date, gioHD) {

    const [apiChiTietNgay, setApiChiTietNgay] = useState(null);
    const [apiChiTietThang, setApiChiTietThang] = useState(null);
    const [thangChiTietSao, setThangChiTietSao] = useState(null);
    const [canChiNam, setCanChiNam] = useState(null);
    const [showTietKhi, setShowTietKhi] = useState(false);

    var intervalAPI;
    const optionsSaoLT = Array.from({length: 12}, (v, i) => {
        return {value: (i + 1), label: 'Tháng ' + (i + 1)}
    });

    useEffect(() => {
        clearInterval(intervalAPI);
        setApiChiTietNgay(null);
        apiCTN(date.d, date.m, date.y);
    }, [date]);
    const tietkhi_arr = {
        1: "image/TietKhi/i_xuanphan.png",
        2: "image/TietKhi/i_thanhminh.png",
        3: "image/TietKhi/i_cocvu.png",
        4: "image/TietKhi/i_lapha.png",
        5: "image/TietKhi/i_tieuman.png",
        6: "image/TietKhi/i_mangchung.png",
        7: "image/TietKhi/i_hachi.png",
        8: "image/TietKhi/i_tieuthu.png",
        9: "image/TietKhi/i_daithu.png",
        10: "image/TietKhi/i_lapthu.png",
        11: "image/TietKhi/i_xuthu.png",
        12: "image/TietKhi/i_bachlo.png",
        13: "image/TietKhi/i_thuphan.png",
        14: "image/TietKhi/i_hanlo.png",
        15: "image/TietKhi/i_suonggiang.png",
        16: "image/TietKhi/i_lapdong.png",
        17: "image/TietKhi/i_tieutuyet.png",
        18: "image/TietKhi/i_daituyet.png",
        19: "image/TietKhi/i_dongchi.png",
        20: "image/TietKhi/i_tieuhan.png",
        21: "image/TietKhi/i_daihan.png",
        22: "image/TietKhi/i_lapxuan.png",
        23: "image/TietKhi/i_vuthuy.png",
        24: "image/TietKhi/i_kinhtrap.png"
    };

    function apiCTN(d, m, y) {
        const dataCTN = {
            loai: 1,
            ngay: d + "-" + m + "-" + y
        }

        clearInterval(intervalAPI);
        SendUrl(dataCTN, '/api/lich/chi-tiet-ngay', 'POST').then(
            function (response) {
                const data = response.data.data;
                setThangChiTietSao({value: data.thang_am, label: "Tháng " + data.thang_am});
                setApiChiTietNgay(data);
                setCanChiNam({can: data.can_nam, chi: data.chi_nam});
                const dataCTT = {
                    thang_am: data.thang_am,
                    chi_nam: data.chi_nam,
                    can_nam: data.can_nam
                };
            },
            function (error) {
                intervalAPI = setInterval(ReloadDataApi, 60000)
            }
        )

    }

    const ReloadDataApi = () => {
        apiCTN(date.d, date.m, date.y)
    }

    const setWidthTietKhi = (date1, date2) => {
        date1 = date1.split("/");
        date2 = date2.split("/");
        const dateNow = new Date(date.m + "/" + date.d + "/" + date.y);
        const datePre = new Date(date1[1] + "/" + date1[0] + "/" + dateNow.getFullYear());
        const dateNext = new Date(date2[1] + "/" + date2[0] + "/" + dateNow.getFullYear());
        const t2 = dateNext.getTime();
        const t1 = datePre.getTime();
        const t3 = dateNow.getTime();
        var value = parseInt((t3 - t1) / (24 * 3600 * 1000)) / parseInt((t2 - t1) / (24 * 3600 * 1000)) * 100;
        if (value == 0) value = 2;
        return {width: "calc(" + value + "% + 2px)"};
    }


    const onChangeSelectThang = (e) => {
        setThangChiTietSao({value: e.value, label: "Tháng " + e.value})
        const dataCTT = {
            thang_am: e.value,
            chi_nam: canChiNam.chi,
            can_nam: canChiNam.can
        };
    }
    const clickABC = (a, b) => {
        const month = parseInt(b);
        if ((a < 0 && month != 1) || (a > 0 && month != 12)) {
            setThangChiTietSao({value: (a + month), label: "Tháng " + (a + month)})
            const dataCTT = {
                thang_am: (a + month),
                chi_nam: canChiNam.chi,
                can_nam: canChiNam.can
            };
        }
    }

    const ImgXuatHanh = (text) => {
        if (text == 'Hỷ thần')
            return '/image/hy_than.png';
        else if (text == 'Tài thần')
            return 'image/tai_than.png';
        else return 'image/hac_than.png';
    }
    const ColorSaoDaiCat = (data) => {
        const xau = data.filter(i => {
            return i.loai == 0
        }).length;
        const tot = data.filter(i => {
            return i.loai == 1;
        }).length;
        return xau == tot ? "text-yellow" : (xau < tot ? "text-red" : "text-black");
    }

    return (
        <>
            {
                apiChiTietNgay ?
                    < TietKhiCalendar
                        date={date}
                        showTietKhi={showTietKhi}
                        setShowTietKhi={() => setShowTietKhi(!showTietKhi)}
                    />
                    : ""
            }
            <div className={'text-center  mt-4'}>
                <a className={'text-green'} href="https://deeplink.lichviet.app/chitietngay"><u>Xem thêm chi tiết</u></a>
            </div>
        </>
    );
}

const TietKhiCalendar = props => {
    const [TietKhi, setTietKhi] = useState(null);

    useEffect(() => {
        const dataCTN = {
            loai: 1,
            ngay: props.date.d + "-" + props.date.m + "-" + props.date.y
        }
        GetTietKhi(dataCTN);
    }, [props.date]);

    const GetTietKhi = (data) => {
        SendUrl(data, '/api/lich/tiet-khi', 'POST').then(
            function (res) {
                if (res.data.status == 1) {
                    setTietKhi(res.data.data);
                }
            }
        )
    }
    const TietKhiImage = {
        1: "image/TietKhi/i_xuanphan.png",
        2: "image/TietKhi/i_thanhminh.png",
        3: "image/TietKhi/i_cocvu.png",
        4: "image/TietKhi/i_lapha.png",
        5: "image/TietKhi/i_tieuman.png",
        6: "image/TietKhi/i_mangchung.png",
        7: "image/TietKhi/i_hachi.png",
        8: "image/TietKhi/i_tieuthu.png",
        9: "image/TietKhi/i_daithu.png",
        10: "image/TietKhi/i_lapthu.png",
        11: "image/TietKhi/i_xuthu.png",
        12: "image/TietKhi/i_bachlo.png",
        13: "image/TietKhi/i_thuphan.png",
        14: "image/TietKhi/i_hanlo.png",
        15: "image/TietKhi/i_suonggiang.png",
        16: "image/TietKhi/i_lapdong.png",
        17: "image/TietKhi/i_tieutuyet.png",
        18: "image/TietKhi/i_daituyet.png",
        19: "image/TietKhi/i_dongchi.png",
        20: "image/TietKhi/i_tieuhan.png",
        21: "image/TietKhi/i_daihan.png",
        22: "image/TietKhi/i_lapxuan.png",
        23: "image/TietKhi/i_vuthuy.png",
        24: "image/TietKhi/i_kinhtrap.png"
    };

    //tính từ lập xuân id lập xuân == 22 chuyển về 0
    const ColorTheoNgayTietKhi =[
        [{min:1,max:7,color:"#ffdf7f"},{min:8,max:14,color:"#dd0000"},{min:15,color:"#6edd00"}],
        [{min:1,color:"#6edd00"}],
        [{min:1,color:"#6edd00"}],
        [{min:1,color:"#6edd00"}],
        [{min:1,max:9,color:"#6edd00"},{min:10,max:12,color:"#006edd"},{min:13,color:"#ffdf7f"}],
        [{min:1,color:"#ffdf7f"}],
        [{min:1,max:5,color:"#ffdf7f"},{min:6,max:14,color:"#000000"},{min:15,color:"#dd0000" }],
        [{min:1,color:"#dd0000"}],
        [{min:1,color:"#dd0000"}],
        [{min:1,color:"#dd0000"}],
        [{min:1,max:9,color:"#dd0000"},{min:10,max:12,color:"#6edd00"},{min:13,color:"#ffdf7f"}],
        [{min:1,color:"#ffdf7f"}],
        [{min:1,max:10,color:"#ffdf7f"},{min:11,max:13,color:"#006edd"},{min:14,color:"#000000"}],
        [{min:1,color:"#000000"}],
        [{min:1,color:"#000000"}],
        [{min:1,color:"#000000"}],
        [{min:1,max:9,color:"#000000"},{min:10,max:12,color:"#dd0000"},{min:13,color:"#ffdf7f"}],
        [{min:1,color:"#ffdf7f"}],
        [{min:1,max:8,color:"#ffdf7f"},{min:9,max:12,color:"#6edd00"},{min:13,color:"#006edd"}],
        [{min:1,color:"#006edd"}],
        [{min:1,color:"#006edd"}],
        [{min:1,color:"#006edd"}],
        [{min:1,max:9,color:"#006edd"},{min:10,max:12,color:"#000000"},{min:13,color:"#ffdf7f"}],
        [{min:1,color:"#ffdf7f"}]
    ];
    const setWidthTietKhi = (moc1, moc2, hientai,id_tietkhi =null) => {
        //mốc1 == 1 thì trừ 1 vì trừ đi cái số trên hình tiết khí (VD: 1)
        var day = moc2 - moc1 - (moc2 > 20 ? 0 : 1);
        return (
            <>
                {
                    Array.from({length: day}, function (v, k) {
                        var color = "#000000";
                        var BG = "#E5E5E5";
                        var Mau = {CL:color,BG:BG}
                        if (k + moc1 + 1 <= hientai){
                            Mau = SetColorTietKhi(k+2,id_tietkhi);
                        }
                        return (
                            <span className={"item-range "} key={k}
                                  style={{
                                      width: "calc( 100% / " + day + " - 2px)",
                                      background:Mau.BG,
                                      color:Mau.CL
                                  }}>
                                {moc1 + k + 1}
                                {
                                    k + 1 == hientai - moc1 ?
                                        <i className="fas fa-caret-down moc-tiet-khi"></i>
                                        : ""
                                }
                            </span>
                        )
                    })
                }
            </>
        )
    }
    const SetColorTietKhi = (k,id_tietkhi)=>{
        if (k){
            var id = 0;
            if (id_tietkhi >=22){
                id = 22 - id_tietkhi;
            }else {
                id = id_tietkhi +2;
            }
            const setColor = ColorTheoNgayTietKhi[id].filter((v)=>{
                return (k >=v.min && k <=v.max) || (k >=v.min && !v.max);
            });
            var BG = setColor.length ? setColor[0].color : "#E5E5E5";
            var color = setColor.length ? "#ffffff" : "#000000";
            return {CL:color,BG:BG};
        }
        return {};
    }
    return (
        <>
            <TietKhiPopup
                show={props.showTietKhi}
                closeTietKhi={props.setShowTietKhi}
            />
            {
                TietKhi ?
                    <Col md={9} className={' mt-md-3 mx-auto p-xs-0'}>
                        <div className={'calendar-day tiet_khi'}>
                            <div className="font-weight-bold title-footer text-uppercase">TIẾT KHÍ</div>
                            <div className={'div-range'}>

                                <div className={'box-range'} onClick={props.setShowTietKhi} style={{
                                    borderColor:SetColorTietKhi(1,TietKhi.moc1.id).BG
                                }}>
                                    <p style={{
                                        position: "absolute", top: "-25px"

                                    }}
                                       className={TietKhi.moc1.ngay_tietkhi === TietKhi.ngay_hientai ? 'text-red tb' : ""}>
                                        {TietKhi.moc1.ngay_tietkhi}
                                    </p>
                                    <img src={TietKhiImage[TietKhi.moc1.id]} alt=""/>
                                    <div className={'text-box'}>
                                        <p className={'m-0 font-weight-bold'}>
                                            {TietKhi.moc1.ten}
                                        </p>
                                        <p className={'m-0 '}>
                                            {TietKhi.moc1.ten_thang}
                                        </p>
                                        <p className={'house-day'}>
                                            Khởi tiết {TietKhi.moc1.gio_tiet_khi}
                                        </p>
                                        <p className={'house-day m-0'}>
                                            {TietKhi.moc1.ngay_duong.replace("-", ' tháng ')}
                                        </p>
                                    </div>
                                </div>
                                <div className={'box-input-range'}>
                                    <div className={'input-range'}>
                                        {setWidthTietKhi(TietKhi.moc1.ngay_tietkhi, TietKhi.moc2.ngay_tietkhi, TietKhi.ngay_hientai,TietKhi.moc1.id)}
                                    </div>
                                </div>
                                <div className={'box-range'} onClick={props.setShowTietKhi}>
                                    <p style={{
                                        position: "absolute",
                                        top: "-25px"
                                    }}>{TietKhi.moc2.chuyen_giao ?? TietKhi.moc2.ngay_tietkhi}</p>
                                    <img src={TietKhiImage[TietKhi.moc2.id]} alt=""/>
                                    <div className={'text-box'}>
                                        <p className={'m-0 font-weight-bold'}>
                                            {TietKhi.moc2.ten}
                                        </p>
                                        <p className={'m-0 '}>
                                            {TietKhi.moc2.ten_thang}
                                        </p>
                                        <p className={'house-day'}>
                                            Chuyển tiết {TietKhi.moc2.gio_tiet_khi}
                                        </p>
                                        <p className={'house-day m-0'}>
                                            {TietKhi.moc2.ngay_duong.replace("-", '  tháng ')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    :
                    <LoaderData />
            }
        </>
    )
}


const Calendars = () => {

    // tính năm nhuận
    function isLeap(year) {
        if ((year % 4) || ((year % 100 === 0) && (year % 400))) return 0;
        else return 1;
    }

    //tính số ngày của 1 tháng
    function daysIn(month, year) {
        return month === 2 ? (28 + isLeap(year)) : 31 - (month - 1) % 7 % 2;
    }

    function startEnd(month, year) {
        var d = new Date(month + '/1/' + year)
        var startIndex = d.getDay();
        var endIndex = daysIn(month, year);
        if (startIndex == 0) {
            startIndex = 6;
        } else {
            startIndex = startIndex - 1;
        }
        return {start: startIndex, end: endIndex};
    }

    //in lịch
    function cld(month, year) {
        var begin = startEnd(month, year);
        const startIndex = begin.start;
        const endIndex = begin.end;
        var amlich;
        var result = [];
        if (startIndex > 0) {
            var monthPre, dayPre, yearPre;
            monthPre = month - 1 == 0 ? 12 : month - 1;
            yearPre = monthPre == 12 ? year - 1 : year;
            dayPre = daysIn(monthPre, yearPre);
            for (var i = dayPre - startIndex + 1; i <= dayPre; i++) {
                amlich = TinhAmLich(i, monthPre, yearPre);
                result.push({ngayduong: i, ngayam: amlich.day, thang: monthPre, nam: yearPre});
            }
        }
        for (var i = 1; i <= endIndex; i++) {
            amlich = TinhAmLich(i, month, year);
            result.push({ngayduong: i, ngayam: amlich.day, thang: month, nam: year});
        }
        if (result.length % 7 > 0) {
            var monthNext, dayNext, yearNext;
            monthNext = month + 1 == 13 ? 1 : month + 1;
            yearNext = monthNext == 1 ? year + 1 : year;
            dayNext = daysIn(monthNext, yearNext);
            for (var i = 0; i < result.length % 7; i++) {
                amlich = TinhAmLich(i + 1, monthNext, yearNext);
                result.push({ngayduong: i + 1, ngayam: amlich.day, thang: monthNext, nam: yearNext});
            }
        }
        return result;
    }


    const d = new Date();
    const dayNow = d.getDate();
    const monthNow = d.getMonth() + 1;
    const yearNow = d.getFullYear();
    const amlichNow = TinhAmLich(dayNow, monthNow, yearNow);
    const tenAmLich = NameCalendar(amlichNow.day, amlichNow.month, amlichNow.year, amlichNow.leap, amlichNow.jd);
    var iWeek = 0;
    const getStartEnd = startEnd(monthNow, yearNow);


    var optionsLT = [];
    for (var j = 0; j < 12; j++) {
        if (monthNow + j > 12) {
            var mr = (monthNow + j) % 12;
            var yr = yearNow + Math.floor((monthNow + j) / 12);
            optionsLT.push({value: mr + "/" + yr, label: 'Tháng ' + mr + ' - ' + yr})
        } else
            optionsLT.push({value: (monthNow + j) + "/" + yearNow, label: 'Tháng ' + (monthNow + j) + ' - ' + yearNow})
    }

    //Dữ liệu ban đầu
    // useEffect(() => function () {
    //     const dataAp = {
    //         ngay: dayNow + "-" + monthNow + "-" + yearNow
    //     }
    //     console.log(dataAp);
    //     apiCTN(dataAp);
    //
    // },[])

    const [display, setDisplay] = useState('d-none');
    const [callAPIDate, setCallAPIDate] = useState({d: dayNow, m: monthNow, y: yearNow});
    const [lichThang, setLichThang] = useState({
        print: cld(monthNow, yearNow),
        m: monthNow,
        y: yearNow,
        start: getStartEnd.start,
        end: getStartEnd.end
    });
    const [lichNgay, setLichNgay] = useState({
        duong: {
            d: dayNow,
            m: monthNow,
            y: yearNow,
            // nameY: getYearCanChi(yearNow)
        },
        am: {
            d: amlichNow.day,
            m: amlichNow.month,
            y: amlichNow.year,
            nameD: tenAmLich[0],
            nameM: tenAmLich[1],
            nameY: tenAmLich[2],
        },
        giohoangdao: GioHoangDao(amlichNow.jd)
    });
    const [hienNgay, setHienNgay] = useState({
        duong: {
            d: dayNow,
            m: monthNow,
            y: yearNow,
        },
        am: {
            d: amlichNow.day,
            m: amlichNow.month,
            y: amlichNow.year,
        }
    });
    const [forcus, setForcus] = useState(null);

    //event page

    const clickLichNgay = (day) => {
        var newDate = new Date(lichNgay.duong.m + "/" + lichNgay.duong.d + "/" + lichNgay.duong.y);
        newDate.setDate(newDate.getDate() + day);
        var al = TinhAmLich(newDate.getDate(), newDate.getMonth() + 1, newDate.getFullYear());
        var tenAmLich = NameCalendar(al.day, al.month, al.year, al.leap, al.jd);
        setLichNgay({
            duong: {
                d: newDate.getDate(),
                m: newDate.getMonth() + 1,
                y: newDate.getFullYear(),
                // nameY: getYearCanChi(newDate.getFullYear())
            },
            am: {
                d: al.day,
                m: al.month,
                y: al.year,
                nameD: tenAmLich[0],
                nameM: tenAmLich[1],
                nameY: tenAmLich[2],
            },
            giohoangdao: GioHoangDao(al.jd)
        });
        setCallAPIDate({d: newDate.getDate(), m: newDate.getMonth() + 1, y: newDate.getFullYear()});
    };

    const clickLichThang = (month) => {
        const newDate = new Date(lichThang.m + "/1/" + lichThang.y);
        newDate.setMonth(newDate.getMonth() + month);
        const getStartEnd = startEnd(newDate.getMonth() + 1, newDate.getFullYear());
        setLichThang({
            print: cld(newDate.getMonth() + 1, newDate.getFullYear()),
            m: newDate.getMonth() + 1,
            y: newDate.getFullYear(),
            start: getStartEnd.start,
            end: getStartEnd.end
        });
    }
    const dayNowClick = () => {
        setLichThang({
            print: cld(monthNow, yearNow),
            m: monthNow,
            y: yearNow,
            start: getStartEnd.start,
            end: getStartEnd.end
        })
        setLichNgay({
            duong: {
                d: dayNow,
                m: monthNow,
                y: yearNow,
                // nameY: getYearCanChi(yearNow)
            },
            am: {
                d: amlichNow.day,
                m: amlichNow.month,
                y: amlichNow.year,
                nameD: tenAmLich[0],
                nameM: tenAmLich[1],
                nameY: tenAmLich[2],
            },
            giohoangdao: GioHoangDao(amlichNow.jd)
        });
        setCallAPIDate({d: dayNow, m: monthNow, y: yearNow});
    }

    const onChangeSelect = (e) => {
        const text = e.value;
        const arr = text.split("/");
        const monthSelect = parseInt(arr[0]);
        const yearSelect = parseInt(arr[1]);
        const SE = startEnd(monthSelect, yearSelect);
        setLichThang({
            print: cld(monthSelect, yearSelect),
            m: monthSelect,
            y: yearSelect,
            start: SE.start,
            end: SE.end
        })
    }

    const hienLichNgay = (d = null, m = null, y = null) => {
        if (d && m && y) {
            const aln = TinhAmLich(d, m, y);
            const tAL = NameCalendar(aln.day, aln.month, aln.year, aln.leap, aln.jd);
            setLichNgay({
                duong: {
                    d: d,
                    m: m,
                    y: y,
                    // nameY: getYearCanChi(hienNgay.duong.y)
                },
                am: {
                    d: aln.day,
                    m: aln.month,
                    y: aln.year,
                    nameD: tAL[0],
                    nameM: tAL[1],
                    nameY: tAL[2],
                },
                giohoangdao: GioHoangDao(aln.jd)
            });
            setCallAPIDate({d: d, m: m, y: y});
        } else {
            const aln = TinhAmLich(hienNgay.duong.d, hienNgay.duong.m, hienNgay.duong.y);
            const tAL = NameCalendar(aln.day, aln.month, aln.year, aln.leap, aln.jd);
            const a = hienNgay.am;
            const d = hienNgay.duong;
            setLichNgay({
                duong: {
                    d: d.d,
                    m: d.m,
                    y: d.y,
                    // nameY: getYearCanChi(hienNgay.duong.y)
                },
                am: {
                    d: a.d,
                    m: a.m,
                    y: a.y,
                    nameD: tAL[0],
                    nameM: tAL[1],
                    nameY: tAL[2],
                },
                giohoangdao: GioHoangDao(aln.jd)
            });
            setCallAPIDate({d: d.d, m: d.m, y: d.y});
        }

    }

    const addClass = (i, f, m, y) => {
        var classHtml = "";
        if (f < lichThang.start || f + 1 > lichThang.end + lichThang.start) {
            classHtml += " opacity";
        }
        if (i == dayNow && m == monthNow && y == yearNow) {
            classHtml += " active";
        }
        // if (i % 2 == 0) {
        //     classHtml += " hoang-dao";
        // } else {
        //     classHtml += " hac-dao";
        // }
        if (iWeek > 6) {
            classHtml += " red";
            iWeek = 0;
        }
        if (f == forcus) {
            classHtml += " forcus";
        }
        return classHtml;
    }

    const openShowDate = (d, m, y) => {
        const aml = TinhAmLich(d, m, y);
        setHienNgay({
            duong: {
                d: d,
                m: m,
                y: y,
            },
            am: {
                d: aml.day,
                m: aml.month,
                y: aml.year
            }
        });
        setDisplay('');
    }

    return (
        <Container>
            <div className={"title-1 py-4"}>Lịch Tháng</div>
            <Col className={'mx-auto calendar'} md={9}>
                <div className={'position-relative'}>
                    <div className={'show-date box-user text-center px-3 pt-0 pb-3 ' + display}>
                        <div className={'header-show align-items-center d-flex justify-content-between move-position'}>
                            <div className={'d-flex justify-content-center py-3  '}>
                                <img src={troi} alt="" width={25}/>
                                <p className={'mx-1'}>
                                    DƯƠNG LỊCH
                                </p>
                            </div>
                            <i onClick={() => {
                                setDisplay('d-none')
                            }} className="fas fa-times-circle close-show-date"></i>
                        </div>
                        <div className={'show-ngay-duong'}>
                            <Select
                                value={{
                                    value: hienNgay.duong.d,
                                    label: "Ngày " + hienNgay.duong.d
                                }}
                                onChange={onChangeSelect}
                            />
                            <Select
                                value={{
                                    value: hienNgay.duong.m,
                                    label: "Tháng " + hienNgay.duong.m
                                }}
                                onChange={onChangeSelect}
                            />
                            <Select
                                value={{
                                    value: hienNgay.duong.y,
                                    label: hienNgay.duong.y
                                }}
                                onChange={onChangeSelect}
                            />
                        </div>
                        <div className={'d-flex py-3'}>
                            <img src={trang} alt="" width={25}/>
                            <p className={'mx-1'}>
                                ÂM LỊCH
                            </p>
                        </div>
                        <div className={'show-ngay-am'}>
                            <Select
                                value={{
                                    value: hienNgay.am.d,
                                    label: "Ngày " + hienNgay.am.d
                                }}
                                onChange={onChangeSelect}
                            />
                            <Select
                                value={{
                                    value: hienNgay.am.m,
                                    label: "Tháng " + hienNgay.am.m
                                }}
                                onChange={onChangeSelect}
                            />
                            <Select
                                value={{
                                    value: hienNgay.am.y,
                                    label: hienNgay.am.y
                                }}
                                onChange={onChangeSelect}
                            />
                        </div>
                        <Button onClick={hienLichNgay} className={"px-4 py-2 mt-4"}>XEM LỊCH</Button>
                    </div>
                    <Button onClick={dayNowClick} className={"px-4 day-now"}>Hôm nay</Button>
                    <div className={'box-style calendar'}>
                        <Row className={'calendar-header'}>
                            <Col md={3}>
                                <div className={'preMonth row '} onClick={() => {
                                    clickLichThang(-1)
                                }}>
                                    <span className={'span-inner'}></span>
                                    <p className={'text-inner'}>Tháng trước</p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <Select
                                    options={optionsLT}
                                    value={{
                                        value: lichThang.m + "/" + lichThang.y,
                                        label: "Tháng " + lichThang.m + " - " + lichThang.y
                                    }}
                                    onChange={onChangeSelect}
                                />
                            </Col>
                            <Col md={3}>
                                <div className={'nextMonth row'} onClick={() => {
                                    clickLichThang(1)
                                }}>
                                    <p className={'text-inner'}>Tháng sau</p>
                                    <span className={'span-inner'}></span>
                                </div>
                            </Col>
                        </Row>

                        <Row className={'calendar-week'}>
                            <div className={'week-item'}>Thứ hai</div>
                            <div className={'week-item'}>Thứ ba</div>
                            <div className={'week-item'}>Thứ tư</div>
                            <div className={'week-item'}>Thứ năm</div>
                            <div className={'week-item'}>Thứ sáu</div>
                            <div className={'week-item'}>Thứ bảy</div>
                            <div className={'week-item red'}>Chủ nhật</div>
                        </Row>
                        <Row className={'mx-0 caledar-days'}>
                            {lichThang.print.map(function (i, index) {
                                iWeek += 1;
                                return (
                                    <div onClick={e => {
                                        hienLichNgay(i.ngayduong, i.thang, i.nam);
                                        setForcus(index);
                                        window.location.href = "#calender-day-href";
                                    }} key={index}
                                         className={'day-item ' + addClass(i.ngayduong, index, i.thang, i.nam)}>
                                        <p className={'duong-lich'}>{i.ngayduong}</p>
                                        <p className={'am-lich'}>{i.ngayam}</p>
                                    </div>
                                );

                            })}
                        </Row>
                    </div>
                    {/*<Row className={'note-calendar px-md-5 py-4'}>*/}
                    {/*    <Col md={6}>*/}
                    {/*        <p className={'note-hoang-dao'}>Ngày hoàng đạo</p>*/}
                    {/*    </Col>*/}
                    {/*    <Col md={6}>*/}
                    {/*        <p className={'note-hac-dao'}>Ngày hắc đạo</p>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </div>
            </Col>
            <div id="calender-day-href" className={"title-1 py-md-4 pb-3"}>Lịch Ngày</div>
            <Col className={'mx-auto calendar'} md={9}>
                <div className={'calendar-day'}>
                    <Row className={'m-0'}>
                        <Col md={6} className={'m-0 p-0'}>
                            <div className={'d-flex justify-content-center py-3 bg-title'}>
                                <img src={troi} alt="" width={25} height={25}/>
                                <p className={'mx-1'}>
                                    DƯƠNG LỊCH
                                </p>
                            </div>
                        </Col>
                        <Col md={6} className={'m-0 p-0'}>
                            <div className={'d-flex justify-content-center py-3 bg-title'}>
                                <img src={trang} alt="" width={25} height={25}/>
                                <p className={'mx-1'}>
                                    ÂM LỊCH
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className={'m-0'}>
                        <Col md={6} className={'m-0 p-0'}>
                            <div className={'card-calendar'}>
                                <div className={'pre-day bt-day'} onClick={() => {
                                    clickLichNgay(-1)
                                }}></div>
                                <p className={'title-card-cl'}>Tháng {lichNgay.duong.m} năm {lichNgay.duong.y}</p>
                                <div className={'day-cl'}>{lichNgay.duong.d}</div>
                                <div className={'weather'}>
                                    <img src={weather} alt="" width={50}/>
                                    <div className={'name-year'}>
                                        <p className={'temperature'}> 24 - 30°C </p>
                                        <p className={'name-y'}>Ấm</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} className={'m-0 p-0'}>
                            <div className={'card-calendar'}>
                                <div className={'next-day bt-day'} onClick={() => {
                                    clickLichNgay(1)
                                }}></div>
                                <p className={'title-card-cl'}>Tháng {lichNgay.am.m} năm {lichNgay.am.y} ({lichNgay.am.nameY})</p>
                                <div className={'day-cl no-color'}>
                                    {lichNgay.am.d}
                                    {/*<p className={'dottor hac-dao'}></p>*/}
                                </div>
                                <div className={'text-amlich'}>
                                    <p>Ngày : {lichNgay.am.nameD}</p>
                                    <p>Tháng: {lichNgay.am.nameM}</p>
                                    <p> Năm: {lichNgay.am.nameY}</p>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </div>
            </Col>
            <div className={'gio-hoang-dao py-4'}>
                {XungKhac(callAPIDate, lichNgay.giohoangdao)}
            </div>
        </Container>

    );
};

export default Calendars;
