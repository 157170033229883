import React from "react";
import {Container} from "react-bootstrap";
import {Helmet} from "react-helmet";

const ChinhSachBM = () => {

    return (
        <>
            <Helmet>
                <title>Lịch Việt</title>
            </Helmet>
            <Container className={'my-4'}>
                <div className={'book'}>
                    <h3 className={'tb'}>Lich Viet privacy policy</h3>
                    <p>
                        This Privacy Policy (“Policy”) provides important information regarding Lịch Việt brands (“Lịch
                        Việt” “we,” or “our”) in respect to user information collected through our mobile applications and
                        the use of the information. You provide consent to this Policy when your access and use Lịch Việt
                        applications (“Products”) and other related services (“Services”) .
                    </p>
                    <h4 className="tb">1. Personal and non-personal information.</h4>
                    <p>
                        We understand the importance of our users’ personal information, and more importantly the
                        information pertaining to children under 13 years of age. We do not collect or require users to
                        enter their personal information when using our Products. We do not collect any personal information
                        from children with our Products.
                    </p>
                    <p>
                        When using our Products, we might read non-personal information (such as internet, Wi-Fi
                        availability, network connection, wake lock, device status, internal and external storage
                        availability and usability) for the use of Product development and Service improvement. The
                        collection of information will be used for the development and functional improvement of our
                        Products to ensure quality user experience. We only collect such information to the extent that
                        allow us to conduct our normal business operation and Products’ research and development.
                    </p>
                    <p>
                        Any information we received will be used internally for the purpose of product development and to
                        third parties performing services on our behalf, who comply with our Privacy Policy. We will not
                        disclose your information publicly unless we have received your consent or under government order.
                    </p>

                    <h4 className="tb">2. Asking for permission purpose in mobile app</h4>
                    <p>
                        Lịch Việt require some device permissions, we declare below:
                    </p>
                    <ul>
                        <li><b>Device location: </b>to update &amp; show the weather forecast at current user location
                            automatically.
                        </li>
                        <li><b>Contacts: </b>allow the app to access &amp; synchronize all phone numbers with our system to
                            invite people to join the events.
                        </li>
                        <li><b>Camera: </b>take photo to change user avatar</li>
                        <li><b>Storage: </b>to save the image that made by our app or change user avatar.</li>
                        <li><b>Accounts: </b>when user want to create Lich Viet account and sign in to use more features (it
                            is optional), the app need to collect the Email ID or Phone number for authentication.
                        </li>
                    </ul>
                    <h4 className="tb">3. Right of access and collection user information</h4>
                    <p>
                        (a) By using the App, you acknowledge that we reserve the right to use the following system APIs to
                        access data on your phone: (1) Read and write to phone contacts, (2 ) Obtain your current location
                        with
                        consent, (3) Write App data to a memory card, (4) Access the Internet from your device. All such
                        access
                        is made by us with your consent, so you undertake and acknowledge that, once you have granted us
                        permission, you will not have any claims against with PPCLINK about this access.
                    </p>
                    <p>
                        (b) With access, we will collect the following information from you:
                    </p>
                    <ul>
                        <li>Personal information: includes information you provide us to verify your account such as name,
                            phone number, ID number, email address;
                        </li>
                        <li>General information: such as information about your phone configuration, PPCLINK version
                            information that you are using for your phone;
                        </li>
                        <li>Your location information: your geolocation data will be stored on the server to help you use
                            the App's search function;
                        </li>
                        <li>Phone contacts: we will store your phone contacts on the server to best support you in using the
                            App and prevent you from losing data. We are committed to respect and not use your phone book
                            for any purpose without your consent;
                        </li>
                    </ul>
                    <p>
                        We do not use any means to track message content, exchange or otherwise to track users while using
                        this
                        App.
                    </p>
                    <p>
                        (c) Your content includes your communications with others, your articles or feedback sent to
                        PPCLINK,
                        your username, images, documents, audio files, your digital products, videos and files. To the
                        extent
                        necessary to provide software and services to you and others, to protect you and services, as well
                        as to
                        improve PPCLINK products and services, you authorize PPCLNK to use Your content is completely free
                        on
                        the software and services of PPCLINK on a worldwide scale; These include rights to copy, store,
                        move,
                        reformat, display, edit, create derivative works, and distribute them through communication tools.
                    </p>
                    <h4 className="tb">4. COPPA</h4>
                    <p>
                        We comply with the Children’s Online Privacy Protection Act. We do not knowingly collect personal
                        information on children under the age of 13. When a user identifies himself or herself as a child
                        under the age of 13 through a support request or through any feedback, we will not collect, store or
                        use, and will delete in a secure manner, any personal information of such user.
                    </p>
                    <h4 className="tb">5.Submitting personal information for prizes and customer support.</h4>
                    <p>
                        From time to time, we may host contests or surveys on our website at www.lichviet.org. We may send
                        out a
                        letter to your email address after you have given us consent through your participation and agree to
                        receive the prize for the event you have participated in. For contests and surveys, we typically
                        require
                        only the information necessary for a child to participate. We only contact the parent for more
                        personalized information for prize-fulfillment purposes when the child wins the contest or completes
                        a
                        survey.
                    </p>
                    <p>
                        When you request support, you may send us an email or contact us on our website. We allow users to
                        contact us if they have support questions about the use of our mobile applications. If you submit a
                        support request, we may receive your email address and other information you provide related to your
                        support request.
                    </p>
                    <h4 className="tb">6. Cookies.</h4>
                    <p>
                        We do not use cookies.
                    </p>
                    <h4 className="tb">7. Third party websites and links.</h4>
                    <ul>
                        <li>We may provide links to our website or third party websites. Our Privacy Policy will have no
                            effect on any third party websites’ privacy policy or their usage of personal information
                            collected. Our Privacy Policy as expressed herein does not apply to other parties, and we do not
                            dictate the collection or usage of information by them. We do not take part in the operation of
                            third party advertisers or websites.
                        </li>
                        <li>YouTube or Google service use: Some of our app may use YouTube API or other services from
                            Google. We comply all the terms in Google Privacy
                            Policy(<a className={'text-blue'}
                                      href="https://policies.google.com/privacy?hl=en-US">https://policies.google.com/privacy?hl=en-US</a>) &amp; YouTube
                            TOS
                            (<a className={'text-blue'}
                                href="https://www.youtube.com/static?gl=VN&template=terms">https://www.youtube.com/static?gl=VN&template=terms</a>).
                        </li>
                    </ul>
                    <h4 className="tb">8. Amendments.</h4>
                    <p>
                        We may amend our privacy policy from time to time by posting the amended Policy on our website,
                        without directly informing our users. Therefore we encourage our users to review this policy
                        frequently. Lịch Việt reserves all rights and interpretation to the Policy and its amendments.
                    </p>
                    <h4 className="tb">9. How to update or remove your information</h4>
                    <p>
                        Lịch Việt keeps your content for as long as necessary in providing its services. If you want to opt
                        out of services, or review or delete your information, please contact us at lichviet@ppclink.com.
                    </p>
                    <h4 className="tb">10. Contact us.</h4>
                    <p className={'mb-1'}>
                        If you have any questions or concerns regarding this Privacy Policy, please send us an email at
                        lichviet@ppclink.com
                    </p>
                    <p className={'mb-1'}>
                        We love hearing your voice. Let’s contact us and stay connected!
                    </p>
                    <p className={'mb-1'}>
                        www.lichviet.app
                    </p>
                    <p className={'mb-1'}> Phone number: +8424 32321265</p>
                    <p className={'mb-1'}>
                        10 Lane 2, Quan Ngua street, Ba Dinh district, Hanoi, Vietnam
                    </p>
                </div>
            </Container>
        </>

    )
}
export default ChinhSachBM;