import React, {useEffect, useState} from "react";
import {Container, Row, Col, Tab, Tabs, Button, InputGroup, FormControl} from "react-bootstrap";
import qr from "./../../image/qr_code.png";
import icon_fb from "./../../image/login-fb.png";
import icon_google from "./../../image/login-google.png";
import icon1 from "./../../image/login1.png";
import icon2 from "./../../image/login2.png";
import loginmb from "./../../image/login-mb.png";
import SendUrl from "../Ui/SendDataUrl";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from "react-google-login";
import LoaderData from "../Ui/loader";
import Popup from "../Ui/Pogup";
import QRCode from "react-qr-code";
import {Helmet} from "react-helmet";

const Login = (props) => {
    // const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
    const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    const [isActive, setActive] = useState(false);
    const handleToggle = () => {
        setActive(!isActive)
    };
    const [loadLogin, setLoad] = useState(false);
    const [dataLogin, setDataLogin] = useState({username: null, password: null});
    const [isTime, setTime] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const [messagePopup, setMessagePopup] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [tokenQr, setTokenQr] = useState('');
    const time = () => {
        loginByQrCode();
    }
    const setTimes = () => {
        if (isTime > 0){
            setTime(isTime - 1);
        }
    }
    useEffect(() => {
        const timer = setInterval(setTimes, 1000);
        return () => clearTimeout(timer);
    });
    useEffect(() => {
        loginByQrCode();
    }, [])
    function loginByQrCode() {
        SendUrl({}, '/api/auth/get-login-qr-code', 'POST', 2).then(function (response) {
            if (response.data.data) {
                setTokenQr(response.data.data);
                setTime(60);
                SendUrl({token: response.data.data}, '/api/auth/scan-qr-code', 'POST', 2).then(function (response) {
                    if (response.data.status == 1) {
                        var output = response.data.data;
                        localStorage.setItem('user', JSON.stringify(output));
                        redirectLogin();
                    }
                })
            }
        })
    }

    const setDataInput = e => {
        if (e.target.name == 'username') {
            setDataLogin({username: e.target.value, password: dataLogin.password});
        }
        if (e.target.name == 'password') {
            setDataLogin({username: dataLogin.username, password: e.target.value});
        }
    };
    const submitLogin = e => {
        e.preventDefault(e);
        if (!dataLogin.username || !dataLogin.password) {
            alert('Bạn chưa nhập số điện thoại hoặc mật khẩu!');
        } else {
            setLoad(true);
            SendUrl(dataLogin, '/api/user/login', 'POST', 2).then(function (response) {
                    setLoad(false);
                    var output = response.data.data;
                    if (response.data.status == 1) {
                        localStorage.setItem('user', JSON.stringify(output));
                        redirectLogin();
                    } else {
                        setIsOpen(!isOpen);
                        setMessagePopup('Tài khoản hoặc mật khẩu không đúng!');
                    }
                },
                function (error) {
                    setLoad(false)
                    console.log(error)
                })
        }
        ;
    };
    const redirectLogin = () => {

        var linkRedirect = window.localStorage.getItem('link_redirect');
        if (linkRedirect) {
            window.localStorage.removeItem('link_redirect');
            window.location.href = linkRedirect;
        } else {
            window.location.href = '/';
        }
    }
    const fbLogin = () => {
        window.FB.login(function (response) {
            if (response.authResponse) {
                var access_token = window.FB.getAuthResponse()['accessToken'];
                getFbUserData(access_token);
            } else {
                document.getElementById('status').innerHTML = 'User cancelled login or did not fully authorize.';
            }
        }, {scope: 'email'});
    }

    function getFbUserData(access_token) {
        window.FB.api('/me', {locale: 'en_US', fields: 'id,first_name,last_name,email,link,gender,locale,picture'},
            function (response) {
                let data = {
                    signal: 'facebook_' + response.id,
                    accessToken: access_token,
                    type: 2
                }
                setLoad(true);
                SendUrl(data, '/api/user/loginbysignal', 'POST', 2).then(function (response) {
                        setLoad(false);
                        var output = response.data.data;
                        if (response.data.status == 1) {
                            localStorage.setItem('user', JSON.stringify(output));
                            redirectLogin();
                        } else {
                            // console.log('Tài khoản hoặc mật khẩu không đúng!');
                            // đổi thành popup thông báo
                            setIsOpen(!isOpen);
                            setMessagePopup('Đăng nhập không thành công. Vui lòng thử lại!');
                        }
                    },
                    function (error) {
                        setLoad(false)
                        console.log(error)
                    })
            });
    }

    const responseGoogle = (response) => {
        // console.log(response)
        if (response.profileObj.email) {
            let data = {
                signal: response.profileObj.email,
                accessToken: response.tokenId,
                type: 1
            }
            setLoad(true);
            SendUrl(data, '/api/user/loginbysignal', 'POST', 2).then(function (response) {
                    setLoad(false);
                    var output = response.data.data;
                    if (response.data.status == 1) {
                        localStorage.setItem('user', JSON.stringify(output));
                        redirectLogin();
                    } else {
                        // console.log('Tài khoản hoặc mật khẩu không đúng!');
                        // đổi thành popup thông báo
                        setIsOpen(!isOpen);
                        setMessagePopup('Đăng nhập không thành công. Vui lòng thử lại!');
                    }
                },
                function (error) {
                    setLoad(false)
                    console.log(error)
                })
        }
    }
    return (
        <Container>
            <Helmet>
                <title>Lịch Việt | Đăng nhập</title>
            </Helmet>
            {loadLogin ?
                <div className={'fixed-loader'}>
                    {LoaderData('big')}
                </div>
                : ""
            }
            <div className={"text-center tl-mobile title-1 py-4"}> Đăng nhập Lịch Việt</div>
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="home" title="Đăng nhập nhanh">
                    <div className={"d-flex justify-content-center tab-item"}>
                        <div className={"item-login"}>Mở app để quét QR code</div>
                        <div className={"item-login"}>
                            {/* <img src={qr} className={isTime == 0 ? 'qr-lock' : ''} alt="" width={150} /> */}
                            <div className={isTime == 0 ? 'qr-lock' : ''}>
                                <QRCode value={tokenQr}/>
                            </div>
                        </div>
                        <div className={isTime == 0 ? 'd-block item-login' : 'd-none item-login'}>
                            <p className={"error-qr-code text-center"}>Mã QR hết hạn</p>
                            <Button className={'reload-qr-code'} onClick={time}> Lấy mã mới</Button>
                        </div>
                        <div className={"item-login or-login"}>
                            <p className={'line-login'}></p>
                            <p className={'m-0'}>Hoặc đăng nhập</p>
                            <p className={'line-login'}></p>
                        </div>
                        <Row className={"w-100 justify-content-center"}>
                            <Col md={4} className={'zindex'}>
                                <div onClick={fbLogin} style={{userSelect: "none"}}
                                     className={"item-login login-with google"}>
                                    <div className={'d-flex'}>
                                        <img src={icon_fb} alt="" width={20}/>
                                        <p>Đăng nhập bằng Facebook</p>
                                    </div>
                                </div>
                                <GoogleLogin
                                    clientId={GOOGLE_CLIENT_ID}
                                    render={renderProps => (
                                        <div onClick={renderProps.onClick} disabled={renderProps.disabled}
                                             className={"item-login login-with google"}>
                                            <div className={'d-flex'}>
                                                <img src={icon_google} alt="" width={18}/>
                                                <p>Đăng nhập bằng Google</p>
                                            </div>
                                        </div>
                                    )}
                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogle}
                                    cookiePolicy={'single_host_origin'}
                                />
                            </Col>
                            <Col md={9} className={' mx-auto d-flex justify-content-between img-active'}>
                                <img src={icon1} alt="" width={250} className={"img-scale-down hidden-xs"}/>
                                <img src={icon2} alt="" width={250} className={"img-scale-down hidden-xs"}/>
                                <img src={loginmb} alt="" className={"img-scale-down hidden-md"}/>
                            </Col>
                        </Row>
                    </div>
                </Tab>
                <Tab eventKey="profile" title="Đăng nhập bằng mật khẩu">
                    <form action="" onSubmit={submitLogin}>
                        <div className={"d-flex justify-content-center tab-item mt-4"}>

                            <div className={"enter-password  "} className={isActive ? "d-none" : ""}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text><i className="fas fa-phone-alt color-icon"></i></InputGroup.Text>
                                    <FormControl name={'username'} onChange={setDataInput}
                                                 placeholder="Nhập số điện thoại"/>
                                </InputGroup>

                                <InputGroup className="mb-3">
                                    <InputGroup.Text><i className="fas fa-unlock-alt color-icon"></i></InputGroup.Text>
                                    <FormControl name={'password'} onChange={setDataInput}
                                                 type={showPassword ? "text" : "password"} placeholder="Nhập mật khẩu"/>
                                    <InputGroup.Text onClick={() => setShowPassword(!showPassword)}><i
                                        className={showPassword ? "active fas fa-eye show-password " : "fas fa-eye show-password "}></i></InputGroup.Text>
                                </InputGroup>
                            </div>
                            <div className={"reset-password"} className={isActive ? "" : "d-none"}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text><i className="fas fa-phone-alt color-icon"></i></InputGroup.Text>
                                    <FormControl onChange={setDataInput} placeholder="Nhập số điện thoại"/>
                                </InputGroup>

                                <InputGroup className="mb-3">
                                    <InputGroup.Text><i className="fas fa-unlock-alt color-icon"></i></InputGroup.Text>
                                    <FormControl type={'password'} placeholder="Đặt mật khẩu mới"/>
                                    <InputGroup.Text><i className="fas fa-eye show-password"></i></InputGroup.Text>
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text><i className="fas fa-unlock-alt color-icon"></i></InputGroup.Text>
                                    <FormControl type={'password'} placeholder="Xác nhận mật khẩu mới"/>
                                    <InputGroup.Text onClick={() => setShowPassword(!showPassword)}><i
                                        className={"fas fa-eye show-password " + showPassword ? "active" : ""}></i></InputGroup.Text>
                                </InputGroup>
                            </div>

                            <Row className={"w-100 justify-content-center login-password"}>
                                <Col md={4} className={'zindex'}>
                                    <div className={"item-login login-with "}>
                                        <Button type="submit" className={"px-4 py-2"}>Đăng nhập</Button>
                                    </div>
                                    <div className={"text-reset-password"}>
                                        <p onClick={handleToggle}>{isActive ? 'Quay lại' : 'Quên mật khẩu'}</p>
                                    </div>
                                </Col>
                                <Col md={9} className={' mx-auto d-flex justify-content-between img-active'}>
                                    <img src={icon1} alt="" width={250} className={"img-scale-down hidden-xs"}/>
                                    <img src={icon2} alt="" width={250} className={"img-scale-down hidden-xs"}/>
                                    <img src={loginmb} alt="" className={"img-scale-down hidden-md"}/>
                                </Col>
                            </Row>
                        </div>
                    </form>
                </Tab>
            </Tabs>
            {isOpen && <Popup
                handleClose={() => setIsOpen(!isOpen)}
                text={messagePopup}
            />}
        </Container>
    );
}

export default Login;