import React, {useState} from "react";
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Active from "./components/active";
import Login from "./components/Login";
import Header from "./components/Header";
import Footers from "./components/Footer";
import KhamPha from "./components/KhamPha";
import Product from "./components/Product";
import ActivePro from "./components/ActivePro";
import TuVi from "./components/TuVi";
import Home from "./components/Home";
import ChinhSachBM from "./components/ChinhSachBaoMat";
import DieuKhoanSuDung from "./components/DieuKhoanSuDung";
import UpFileTxt from "./components/Ui/UpFileTxt";
import TaiAppNhanQua from "./components/TaiAppNhanQua";
import Contact from "./components/Contact/Contact";
import FacebookMessagerChat from "./components/FacebookMessagerChat";
import HDSDLichVietBeta from "./components/HuongDanSuDungLichVietBeta";

const App = () => {

    return (
        <div className="App">
            <BrowserRouter>
                <Header/>
                <FacebookMessagerChat/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/kham-pha" element={<KhamPha/>}/>
                    <Route path="/lich-van-nien" element={<Product/>}/>
                    <Route path="/mua-lich-viet-pro" element={<ActivePro/>}/>
                    <Route path="/tu-vi" element={<TuVi/>}/>
                    <Route path="/chinh-sach-bao-mat" element={<ChinhSachBM/>}/>
                    <Route path="/dieu-khoan-su-dung" element={<DieuKhoanSuDung/>}/>
                    <Route path="/download/app-ads.txt" element={<UpFileTxt/>}/>
                    <Route path="/tai-app-nhan-qua" element={<TaiAppNhanQua/>}/>
                    <Route path="/lien-he" element={<Contact/>}/>
                    <Route path="/kich-hoat-pro" element={<Active/>}/>
                    <Route path="/huong-dan-dung-thu-lich-viet" element={<HDSDLichVietBeta/>}/>
                </Routes>
                <Footers/>
            </BrowserRouter>
        </div>
    );
}

export default App;
