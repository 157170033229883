import React ,{useEffect,useState} from "react";
import {Container, Col} from "react-bootstrap";
import Slider from "../Ui/Slider";
import Calendars from "../Ui/Calendar";
import {Helmet} from "react-helmet";


const Home = () => {
    return (
        <>
            <Helmet>
              <title>Lịch Việt | Trang Chủ</title>
            </Helmet>
            <Slider
                banner={['image/slider/slider45.png']}
                // click={1}
                title={"Lịch Việt - Lịch Vạn Niên 2022"}
                description={"Lịch vạn niên điện tử đặc biệt có tác dụng tra cứu thông tin chính xác về ngày tháng năm dương lịch và âm lịch, tra cứu ngày tốt xấu , giờ hoàng đạo, hắc đạo, đổi từ lịch âm sang lịch dương…"}
                link={false}
            />
            <Calendars/>
        </>
    );
};

export default Home;