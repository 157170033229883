import React, { useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown, Container, Row, Button, Col, Dropdown } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import iconPro from "./../../image/crown.png";
import iconProUser from "./../../image/icon_pro.png";
import logo from "./../../image/logo.png";
import userdefault from "../../image/default-profile.png";
import $ from "jquery";

const Header = () => {
    const getLocal = JSON.parse(localStorage.getItem('user'));

    const [user, setUser] = useState(null);

    useEffect(() => {
        UpdateLinkAvatar(getLocal)
        setUser(getLocal);
    }, [])
    const UpdateLinkAvatar = (getLocal) => {
        if (getLocal && getLocal.avatar && getLocal.avatar.search('https') < 0){
            getLocal.avatar = "https://cdn.timnha24h.com"+getLocal.avatar
            localStorage.setItem('user',JSON.stringify(getLocal))
        }
    }
    const [classMenuMB, setClassMenuMB] = useState(false);

    const showLogin = () => {
        if (user) {
            const detailPremium = user.premiums[0];
            const checkPremium = user.premium && user.premium == 1 ? true : false;
            return (
                <div className={'position-relative d-flex justify-content-center avatar-header'}>
                    {
                        user.avatar == "" || !user.avatar ?
                            <img src={userdefault} alt="Avatar" width={50} height={50} className={'avatar'} />
                            :
                            <img src={user.avatar} alt="Avatar" width={50} height={50} className={'avatar'} />
                    }
                    <div className={'show-detail-user'}>
                        <Row className={'box-user bg-white'}>
                            <Col md={3} className={'d-flex justify-content-center align-items-center'}>
                                <div className="position-relative d-inline">
                                    {
                                        user.avatar == "" || !user.avatar ?
                                            <img src={userdefault} alt="Avatar" width={80} height={80} className={'avatar'} />
                                            :
                                            <img src={user.avatar} alt="Avatar" width={80} height={80} className={'avatar'} />
                                    }
                                    {
                                        user.premium == 1 ?
                                            <img src={iconProUser} alt="" className={'icon-pro-user'} width={35} />
                                            :
                                            ""
                                    }

                                </div>
                            </Col>
                            <Col md={9}>
                                <p className={'title2'}>{user.full_name}</p>
                                <p>{checkPremium ? "Thành viên Pro từ " + detailPremium.start_time.split(" ")[0] : "Chưa phải thành viên Pro"}</p>
                                <p>HSD: {checkPremium ? detailPremium.end_time.split(" ")[0] : ""}</p>
                                <p>ID: {user.id}</p>
                            </Col>
                            <div onClick={logout} className={'logout-pc'}>
                                <i className="fas fa-sign-out-alt"></i>
                            </div>
                        </Row>
                    </div>
                </div>
            );
        } else {
            return (
                <a onClick={closeMenuMb} href="/login">
                    <Button>Đăng nhập</Button>
                </a>
            );
        }
    }
    const logout = () => {
        localStorage.removeItem('user');
        window.location.reload();
    }
    const showLoginMB = () => {
        if (user) {
            const detailPremium = user.premiums[0];
            const checkPremium = user.premium && user.premium == 1 ? true : false;
            return (
                <Row className={'box-user bg-white d-flex align-items-center'}>
                    <div className={'d-flex justify-content-center align-items-center '}>
                        <div className="position-relative d-inline">
                            {
                                user.avatar == "" || !user.avatar ?
                                    <img src={userdefault} alt="Avatar" width={80} height={80} className={'avatar'} />
                                    :
                                    <img src={user.avatar} alt="Avatar" width={80} height={80} className={'avatar'} />
                            }
                            {
                                user.premium == 1 ?
                                    <img src={iconProUser} alt="" className={'icon-pro-user'} width={35} />
                                    :
                                    ""
                            }

                        </div>
                    </div>
                    <div>
                        <p className={'title2'}>{user.full_name}</p>
                        <p>{checkPremium ? "Thành viên Pro từ " + detailPremium.start_time.split(" ")[0] : "Chưa phải thành viên Pro"}</p>
                        <p>HSD: {checkPremium ? detailPremium.end_time.split(" ")[0] : ""}</p>
                        <p>ID: {user.id}</p>
                    </div>
                    <div onClick={logout} className={'logout-mb'}>
                        <i className="fas fa-sign-out-alt"></i>
                    </div>
                </Row>

            );
        } else {
            return (
                <a onClick={closeMenuMb} href="/login">
                    <Button>Đăng nhập</Button>
                </a>
            );
        }
    }
    const showDropdown = () => {
        $('.dropdown', this).toggleClass('show');
        $('.dropdown-menu', this).toggleClass('show');
    }
    const openMenuMB = () => {
        setClassMenuMB(true);
    };
    const closeMenuMb = (e) => {
        setClassMenuMB(false);
    };
    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container className={'ipad-navbar'}>
                    <div onClick={openMenuMB} className={'open-menu-mb hidden-md'}>
                        <i className="fas fa-bars"/>
                    </div>
                    <NavLink to="/" className={'m-0'}>
                        <img src={logo} width={180} className={'img-mb__150'} />
                    </NavLink>
                    {/*<div className={'search-mobile hidden-md'}>*/}
                    {/*    <i className="fas fa-search"></i>*/}
                    {/*</div>*/}
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav>
                            <Row className="align-items-center">
                                <Dropdown>
                                    <Dropdown.Toggle onClick={() => showDropdown()} id="dropdown-basic" className="active-pro-dropdown">
                                        <img src={iconPro} className="mr-2 pb-1"/>
                                        Nâng cấp lịch việt Pro
                                    </Dropdown.Toggle>

                                    <div className="w-100 dropdown-menu">
                                        <NavLink to="/mua-lich-viet-pro" onClick={() => showDropdown()} className="py-2 dropdown-item">Mua Lịch Việt Pro</NavLink>
                                        <NavLink to="/kich-hoat-pro" onClick={() => showDropdown()} className="py-2 dropdown-item">Kích hoạt bằng mã</NavLink>
                                    </div>
                                </Dropdown>
                                {showLogin()}

                            </Row>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Navbar expand="lg" className={classMenuMB ? "ipad-flex open" : 'ipad-flex'}>
                <div className={'login-menu__mb'}>
                    {showLoginMB()}
                    <Dropdown className="mt-2">
                        <Dropdown.Toggle id="dropdown-basic1" className="active-pro-dropdown">
                            <img src={iconPro} className="mr-2 pb-1" alt={""}/>
                            Nâng cấp lịch việt Pro
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="w-100">
                            <NavLink to="/mua-lich-viet-pro" onClick={closeMenuMb} className="py-2 dropdown-item">Mua Lịch Việt Pro</NavLink>
                            <NavLink to="/kich-hoat-pro" onClick={closeMenuMb} className="py-2 dropdown-item">Kích hoạt bằng mã</NavLink>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <Container>
                    <Nav className="route-link">
                        <NavLink onClick={closeMenuMb} to="/" className="mr-4 text-dark nav-link">TRANG CHỦ</NavLink>
                        <NavLink onClick={closeMenuMb} to="/tu-vi" className="mr-4 text-dark nav-link">TỬ VI</NavLink>
                        <NavLink onClick={closeMenuMb} to="/kham-pha" className="mr-4 text-dark nav-link">KHÁM
                            PHÁ</NavLink>
                        <NavLink onClick={closeMenuMb} to="/lich-van-nien" className="mr-4 text-dark nav-link">ỨNG DỤNG LỊCH VIỆT</NavLink>
                        <NavLink onClick={closeMenuMb} to="/lien-he" className="mr-4 text-dark nav-link">LIÊN HỆ</NavLink>
                    </Nav>
                </Container>
            </Navbar>
            <div onClick={closeMenuMb} className={classMenuMB ? "close-menu open" : "close-menu"}>
                <i className="far fa-times-circle"/>
            </div>
        </>

    );
}

export default Header;