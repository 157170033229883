import React from "react";
import {Carousel, Col, Row} from "react-bootstrap";

const ImageProduct = () => {
    return (
        <>
            <div className={'sroll hidden-md'}>
                <Row className={'srcoll-img'} style={{width:'630%'}}>
                    <img style={{width:'calc(100% / 7 - 15px)'}} src={'image/product/sp1.jpg'} alt=""/>
                    <img style={{width:'calc(100% / 7 - 15px)'}} src={'image/product/sp2.jpg'} alt=""/>
                    <img style={{width:'calc(100% / 7 - 15px)'}} src={'image/product/sp3.jpg'} alt=""/>
                    <img style={{width:'calc(100% / 7 - 15px)'}} src={'image/product/sp4.jpg'} alt=""/>
                    <img style={{width:'calc(100% / 7 - 15px)'}} src={'image/product/sp5.jpg'} alt=""/>
                    <img style={{width:'calc(100% / 7 - 15px)'}} src={'image/product/sp6.jpg'} alt=""/>
                    <img style={{width:'calc(100% / 7 - 15px)'}} src={'image/product/sp7.jpg'} alt=""/>
                </Row>
            </div>
            <Carousel className={'hidden-xs'} variant="dark">
                <Carousel.Item>
                    <Row className={'img-cover'}>
                        <Col md={4}>
                            <img src={'image/product/sp1.jpg'} alt=""/>
                        </Col>
                        <Col md={4}>
                            <img src={'image/product/sp2.jpg'} alt=""/>
                        </Col>
                        <Col md={4}>
                            <img src={'image/product/sp3.jpg'} alt=""/>
                        </Col>
                    </Row>
                </Carousel.Item>
                <Carousel.Item>
                    <Row className={'img-cover'}>
                        <Col md={4}>
                            <img src={'image/product/sp4.jpg'} alt=""/>
                        </Col>
                        <Col md={4}>
                            <img src={'image/product/sp5.jpg'} alt=""/>
                        </Col>
                        <Col md={4}>
                            <img src={'image/product/sp6.jpg'} alt=""/>
                        </Col>
                    </Row>
                </Carousel.Item>
                <Carousel.Item>
                    <Row className={'img-cover'}>
                        <Col md={4}>
                            <img src={'image/product/sp7.jpg'} alt=""/>
                        </Col>
                    </Row>
                </Carousel.Item>
            </Carousel>
        </>

    );
}

export default ImageProduct;