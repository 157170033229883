import React, {useEffect, useState} from "react";
import {Container, Row, Col, Button, InputGroup, FormControl} from "react-bootstrap";
import {Helmet} from "react-helmet";


const HDSDLichVietBeta = () => {

        return (
            <>
                <Helmet>
                    <title>Lịch Việt | Hướng dẫn sử dụng</title>
                </Helmet>
                <Container className={'mt-4 px-3'}>
                    <h5 className={'tb title-page text-center'}>Hướng dẫn đăng ký dùng thử Lịch Việt phiên bản 9.0</h5>
                    <div className={'ios'}>
                        <Button className={'border'}>Với iOS</Button>
                        <div className={'my-3'}>
                            <p className={'m-0'}><strong>Truy cập đường link sau và làm theo hướng dẫn:</strong></p>
                            <a className={'text-blue'}
                               href="https://testflight.apple.com/join/h3acKhv8">https://testflight.apple.com/join/h3acKhv8</a>
                        </div>
                        <div className={'video '}>
                            <p className={'mb-2'}><strong>Video hướng dẫn:</strong></p>
                            <div className="video-wrapper">
                                <iframe width="787" height="703" src="https://www.youtube.com/embed/t0k--1NfeCY"
                                        title="HD Cách sử dụng Testflight" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                    <div className={'android mt-4 '}>
                        <Button className={' border '}>Với Android</Button>
                        <div className={'my-3'}>
                            <p className={'m-0'}> &#x25CF; Truy cập đường link sau: <br/>
                                <a className={'text-blue ml-3'} href={'https://play.google.com/apps/testing/com.somestudio.lichvietnam'}>
                                    https://play.google.com/apps/testing/com.somestudio.lichvietnam
                                </a>
                            </p>
                            <p className={'m-0'}> &#x25CF; Nhấn nút Become a Tester / Trở thành người thử nghiệm</p>
                            <p className={'mb-2'}>&#x25CF; Tải app tại đây: <br/>
                                <a className={'text-blue ml-3'} href={'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.somestudio.lichvietnam'}>
                                    https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.somestudio.lichvietnam
                                </a>
                            </p>
                            <p className={'m-0'}><strong className={'text-red'}>*<u>Lưu ý:</u></strong> <br/>Sau khi nhấn nút Become a Tester / Trở thành người thử nghiệm, bạn có thể phải chờ một lúc để có thể tải được phiên bản dùng thử.
                            </p>
                        </div>
                    </div>
                    <div className={'line-dashed'}><div></div></div>
                    <div className={'my-4 '}>
                        <div className={'button-style my-4'}>
                            <span>Lịch Việt 9.0 có gì mới?</span>

                        </div>
                        <div className={'my-3'}>
                            <p className={'m-0 text-red'}><strong>Dịch vụ "Chọn ngày tốt"</strong></p>
                            <p className={'m-0'}>
                                Sản phẩm độc quyền của Lịch Việt, được các
                                chuyên gia của Lịch Việt đã nghiên cứu, tổng hợp
                                nhiều phương pháp khác nhau bao gồm: sử dụng
                                hệ thống lý luận thần sát Hiệp Kỷ Biện Phương Thư
                                làm cơ sở để tính toán lập trình, kết hợp
                                Thiên - Địa - Nhân cùng một số phương pháp khác
                                để chọn ra: Năm, Tháng, Ngày, Giờ tốt, xấu phục vụ
                                người dùng.
                            </p>
                        </div>
                        <div className={'my-3'}>
                            <p className={'m-0 text-red'}><strong>Giao diện hoàn toàn mới</strong></p>
                            <p className={'m-0'}>
                                Giao diện được làm mới hiện đại, thân thiện với
                                người dùng.
                            </p>
                        </div>
                        <div className={'my-3'}>
                            <p className={'m-0 text-red'}><strong>“Lịch tháng” & “Chi tiết ngày” hoàn toàn mới</strong></p>
                            <p className={'m-0'}>
                                Được làm mới hoàn toàn, hiện đại và thân thiện
                                với người dùng. Thông tin hiển thị rõ ràng đầy đủ
                                hơn với ngày giờ tốt xấu, tiết khí, các việc nên làm,
                                không nên làm...
                            </p>
                        </div>
                        <div className={'my-3'}>
                            <p className={'m-0 text-red'}><strong>La kinh</strong></p>
                            <p className={'m-0'}>
                                Tính năng xem phương hướng cát hung theo năm,
                                tháng trên La Kinh là một công cụ không thể thiếu
                                trong Phong Thủy, được ra mắt trên Lịch Việt 9.0.
                                La Kinh tích hợp sẵn trên ứng dụng giúp bạn xem
                                được hướng tốt xấu hợp mệnh hợp tuổi, ngũ hành,
                                can chi, bát quái, phương vị,…rất nhanh chóng
                                và tiện lợi.
                            </p>
                        </div>
                        <div className={'my-3'}>
                            <p className={'m-0 text-red'}><strong>Thay đổi các gói dịch vụ</strong></p>
                            <p className={'m-0'}>
                                Gói Lịch Việt Pro cũ được chuyển đổi thành gói
                                Chọn ngày tốt với thời hạn sử dụng tương đương.
                                Với khách hàng trọn đời của gói Pro cũ, khi
                                chuyển đổi sang gói dịch vụ mới vẫn được sử
                                dụng các tính năng theo gói dịch vụ cũ, bỏ
                                quảng cáo trọn đời và được tặng 3 năm sử dụng
                                dịch vụ Chọn ngày tốt.
                            </p>
                        </div>
                    </div>
                </Container>
            </>
        );
    }
;


export default HDSDLichVietBeta;