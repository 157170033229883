import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import SendDataUrl from "../Ui/SendDataUrl";
import LoaderData from "../Ui/loader";
import {Helmet} from "react-helmet";


const Contact = () => {
    const [modalIsOpen,setmodalIsOpen] = useState(false);
    const [load,setLoad] = useState(false);
    const SubmitFormContac = e => {
        e.preventDefault(e);
        const data = {
            name: e.target[0].value,
            email: e.target[1].value,
            phone: e.target[2].value,
            content: e.target[3].value,
        }
        setLoad(true);
        SendDataUrl(data,'/api/user/contact','POST',2).then(v=>{
            if (v.data.status == 1){
                setmodalIsOpen(true);
            }
            setLoad(false);
        },k=>{
            setLoad(false);
        })
    }
    const closeModal = () => {
        setmodalIsOpen(false);
        window.location.reload();
    }
    const InputHiddenPlaceholder = (e)=>{
        const id = e.target.id;
        if (e.target.value){
            document.getElementById(id+'-hidden-input').classList.add('d-none');
        }else {
            document.getElementById(id+'-hidden-input').classList.remove('d-none');
        }
    }
    return (
        <>
            {load === false ? "" : LoaderData('big page')}
            <Helmet>
                <title>Lịch Việt | Liên hệ</title>
            </Helmet>
            <div className="container">
                <div className="position-relative" id="about-us">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 ">
                            <h2 className="title-banner tb">
                                Liên hệ
                            </h2>
                            <p className=" style-text4 mt-4">Bạn vui lòng để lại tin nhắn, nhân viên của PPCLINK sẽ liên hệ hỗ
                                trợ bạn trong thời gian sớm nhất.</p></div>
                        <div className="col-md-6 col-12 ml-auto">
                            <img width="506" height="305"
                                 src="https://ppclink.com/wp-content/uploads/2021/12/banner_lienhe.png"
                                 className="image wp-image-366  attachment-full size-full"
                                 alt="" loading="lazy"/>
                        </div>
                    </div>
                    <div className="backgroup-banner" />
                </div>

                <div className="form-recruitment div-card mt-4">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 ">
                            <form action="#" onSubmit={SubmitFormContac}>
                                <h5 className="name-column tb mb-4">Nhập thông tin liên hệ</h5>
                                <div className="form-group">
                                    <label htmlFor="name" className="placeholder-input">
                                        <i className="far fa-user"/>
                                        <p className="text-placeholder" id={'name-hidden-input'}>
                                            Họ tên <span className="text-red">*</span>
                                        </p>
                                    </label>
                                    <input onChange={InputHiddenPlaceholder} type="text" name="name" id="name" className="input-edit" required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email" className="placeholder-input">
                                        <i className="far fa-envelope"/>
                                        <p className="text-placeholder" id={'email-hidden-input'}>
                                            Email <span className="text-red">*</span>
                                        </p>
                                    </label>
                                    <input onChange={InputHiddenPlaceholder} type="email" name="email" id="email" className="input-edit" required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phone" className="placeholder-input">
                                        <i className="fas fa-phone-alt"/>
                                        <p className="text-placeholder" id={'phone-hidden-input'}>
                                            Số điện thoại <span className="text-red">*</span>
                                        </p>
                                    </label>
                                    <input onChange={InputHiddenPlaceholder} type="text" name="phone" id="phone" className="input-edit" required/>
                                </div>
                                <div className="form-group text-area content">
                                    <label htmlFor="note" className="placeholder-input ">
                                        <i className="far fa-clipboard"/>
                                        <p className="text-placeholder" id={'note-hidden-input'}>
                                            Nhập nội dung <span className="text-red">*</span>
                                        </p>
                                    </label>
                                    <textarea onChange={InputHiddenPlaceholder} id="note" name="introduce" className="input-edit" required/>
                                </div>
                                <div className="mt-md-5 sb-contact">
                                    <Button type="submit" className="submit-form" style={{width:200}} value="">Gửi</Button>
                                </div>
                            </form>
                        </div>
                        <div className="col-sm-12 col-md-6 ">
                            <div className="name-column"/>
                            <div className="address-cty mt-3">
                                <h5 className="style-text3 tb mb-4">Công ty cổ phần truyền thông &amp; phát triển phần mềm
                                    Cát
                                    Việt</h5>
                                <p className="style-text4">Số 10, ngõ 2, phố Quần Ngựa, phường Liễu Giai, quận Ba Đình,
                                    thành phố Hà Nội </p>
                                <div className="textwidget"/>
                                <div className="row w-100 mt-4 phone-email">
                                    <div className="widget col-md-5 col-sm-5">
                                        <p className="style-text4 mb-1">Số điện thoại</p>
                                        <p className={'style-text4 tb'}>024 32321265</p>
                                    </div>
                                    <div className="widget col-md-5 col-sm-5">
                                        <p className="style-text4 mb-1">E-mail</p>
                                        <p className={'style-text4 tb'}>lichviet@ppclink.com</p>
                                    </div>
                                </div>
                                <img src="https://ppclink.com/wp-content/themes/ppclink/assets/images/address.png"
                                     className="img-cover" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={modalIsOpen} onHide={closeModal}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered>
                <Modal.Body>
                    <div className="thank-kiu">Chúng tôi đã nhận được liên hệ của bạn.</div>
                    <div className="lh">Cám ơn!</div>
                    <button data-dismiss="modal" aria-label="Close" onClick={closeModal} type="button">Đóng</button>
                </Modal.Body>
            </Modal>
        </>
    );

}
export default Contact;