import React, {useEffect, useRef, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import logo from "./../../image/logo.png";
import apple from "./../../image/apple.png";
import android from "./../../image/android.png";
import hawaii from "./../../image/hawai.png";
import fb from "./../../image/facebook.png";
import yt from "./../../image/youtube.png";
import email from "./../../image/email.png";
import $ from 'jquery';
const Footers = () => {
    const heightFooter = useRef();
    const [h,setH] = useState(1100)
    useEffect(()=>{
        if (heightFooter.current){
            setH(heightFooter.current.offsetWidth)
        }
    })
    window.onscroll = function () {
        scrollFunction()
    };

    function scrollFunction() {
        if (window.pageYOffset > 0 && h > 800) {
            $('#go-up').show();
        } else {
            $('#go-up').hide();
        }
    }
    const srcollTopp = () =>{
        $('html, body').animate({scrollTop: 0});
    }
    return (
        <footer ref={heightFooter}>
            <Container>
                <hr/>

                <Row className={'pt-2'}>
                    <Col md={12} className={'hidden-md'}>
                        <div className="font-weight-bold title-footer">Tải ứng dụng Lịch Việt</div>
                        <Row className=" pt-3 icon-footer__s">
                            <Col md={3}>
                                <a href="https://itunes.apple.com/app/id585253443?mt=8" target="_blank">
                                    <img src={apple} width={30} alt=""/>
                                </a>
                            </Col>
                            <Col md={3}>
                                <a href="https://play.google.com/store/apps/details?id=com.somestudio.lichvietnam" target="_blank">
                                    <img src={android} width={30} alt=""/>
                                </a>
                            </Col>
                            <Col md={3}>
                                <a href="http://cdc.hispace.hicloud.com/000000hM" target="_blank">
                                    <img src={hawaii} width={30} alt=""/>
                                </a>
                            </Col>
                        </Row>
                        <hr/>
                        <div className="font-weight-bold title-footer ">Kết nối với chúng tôi</div>
                        <Row className=" pt-3 icon-footer__s">
                            <Col md={3}>
                                <a href="https://www.facebook.com/lichviet.ppclink" target="_blank">
                                    <img src={fb} width={30} alt=""/>
                                </a>
                            </Col>
                            <Col md={3}>
                                <a href="https://ppclink.com/apps/contact-us/" target="_blank">
                                    <img src={email} width={30} alt=""/>
                                </a>
                            </Col>
                            <Col md={3}></Col>
                        </Row>
                        <hr/>
                    </Col>
                    <Col md={4} >
                        <div className="d-block hidden-xs">
                            <img src={logo} width={180}></img>
                        </div>
                        <div className="font-weight-bold py-3">
                            Công ty cổ phần Truyền Thông & Phát Triển phần mềm Cát Việt
                        </div>
                        <ul className={"footer-address"}>
                            <li><i className="fas fa-map-marker-alt"></i><p> Số 10, ngõ 2, phố Quần Ngựa, phường Liễu Giai, quận Ba Đình, thành phố Hà Nội</p></li>
                            <li> <i className="fas fa-phone-alt"></i><a href={'tel:02432321265'}> 024 32321265</a></li>
                            <li><i className="fas fa-envelope"></i> <a  href="mailto:lichviet@ppclink.com">lichviet@ppclink.com</a></li>
                        </ul>
                    </Col>
                    <Col md={4} className="ml-auto hidden-xs">
                        <div className="font-weight-bold title-footer">Danh mục</div>
                        <ul className={"mt-3"}>
                            <li><a href="/">Trang chủ</a></li>
                            <li><a href="/tu-vi">Tử vi</a></li>
                            <li><a href="/kham-pha">Khám phá</a></li>
                            <li><a href="/lich-van-nien">Ứng dụng Lịch Việt</a></li>
                            <li><a href="/mua-lich-viet-pro">Nâng cấp Lịch Việt Pro</a></li>
                            <li><a href="/dieu-khoan-su-dung">Điều khoản sử dụng</a></li>
                            <li><a href="/chinh-sach-bao-mat">Chính sách bảo mật</a></li>
                        </ul>
                    </Col>
                    <Col md={3} className={'hidden-xs'}>
                        <div className="font-weight-bold title-footer ">Kết nối với chúng tôi</div>
                        <Row className="py-4">
                            <Col md={3}>
                                <a href="https://www.facebook.com/lichviet.ppclink" target="_blank">
                                    <img src={fb} width={30} alt=""/>
                                </a>
                            </Col>
                            <Col md={3}>
                                <a href="https://ppclink.com/apps/contact-us/" target="_blank">
                                    <img src={email} width={30} alt=""/>
                                </a>
                            </Col>
                            <Col md={3}></Col>
                        </Row>
                        <div className="font-weight-bold title-footer">Tải ứng dụng Lịch Việt</div>
                        <Row className="py-4">
                            <Col md={3}>
                                <a href="https://itunes.apple.com/app/id585253443?mt=8" target="_blank">
                                    <img src={apple} width={30} alt=""/>
                                </a>
                            </Col>
                            <Col md={3}>
                                <a href="https://play.google.com/store/apps/details?id=com.somestudio.lichvietnam" target="_blank">
                                    <img src={android} width={30} alt=""/>
                                </a>
                            </Col>
                            <Col md={3}>
                                <a href="http://cdc.hispace.hicloud.com/000000hM" target="_blank">
                                    <img src={hawaii} width={30} alt=""/>
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>    
                <hr></hr>
                <div className={' mb-2 copy-footer'} >
                   <span className={'text-black '}>© Copyright 2022 by PPCLINK. Read our </span><a href="/chinh-sach-bao-mat" className={'copyright'}>&nbsp;Privacy policy.</a>
                </div>
            </Container>
            <div className="float-footer">
                <div id="go-up" onClick={srcollTopp}>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="long-arrow-up" role="img"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
                         className="svg-inline--fa fa-long-arrow-up fa-w-10 fa-3x">
                        <path fill="currentColor"
                              d="M58.427 225.456L124 159.882V456c0 13.255 10.745 24 24 24h24c13.255 0 24-10.745 24-24V159.882l65.573 65.574c9.373 9.373 24.569 9.373 33.941 0l16.971-16.971c9.373-9.373 9.373-24.569 0-33.941L176.971 39.029c-9.373-9.373-24.568-9.373-33.941 0L7.515 174.544c-9.373 9.373-9.373 24.569 0 33.941l16.971 16.971c9.372 9.373 24.568 9.373 33.941 0z"
                              className=""></path>
                    </svg>
                </div>
            </div>
        </footer>

    );
}

export default Footers;